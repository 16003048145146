import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import Home from "./components/Home";
import CheckFever from "./components/CheckFever";
import Thermonitor from "./components/Thermonitor";
import About from "./components/About";
import Privacy from "./components/Privacy";
import Contact from "./components/Contact";
import Press from "./components/Press";
import ScrollToTop from "./components/ScrollToTop";
import "./App.scss";
import ReactGA from 'react-ga';

class App extends Component {

  initializeReactGA() {
    ReactGA.initialize("GTM-K2Q9S69"); //GTAG_ID
    ReactGA.pageview('/home');
  }

  componentDidMount() {
    this.initializeReactGA()
  }

  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <div>
            <Route exact path="/" component={Home} />
            <Route exact path="/first" render={(props) => <Home {...props} referral="first" />} />
            <Route exact path="/second" render={(props) => <Home {...props} referral="second" />} />
            <Route path="/checkfever" component={CheckFever} />
            <Route path="/ithermonitor" component={Thermonitor} />
            <Route path="/about" component={About} />
            <Route path="/privacy" component={Privacy} />
            <Route path="/contact" component={Contact} />
            <Route path="/press" component={Press} />
          </div>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
