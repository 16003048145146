import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import {
  Fa,
  Button,
  FormInline,
  Input,
  Modal,
  ModalHeader,
  ModalBody
} from "mdbreact";
import DoseCalculator from "./DoseCalculator";
import bchlogo from "../images/bch-logo.svg";
import hmslogo from "../images/hms-logo.svg";
import hmlogo from "../images/hm-logo.svg";
import step1 from "../images/step1.jpg";
import step2 from "../images/step2.jpg";
import step3 from "../images/step3.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import * as GuidanceActions from "../actions/guidance-actions";
import * as SessionTracker from "../util/session-tracker";
import queryString from "query-string";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false,
      temperature: 0,
      temperatureUnit: "F",
      signupSuccess: false
    };
  }

  componentDidMount() {
    var referralName = "";
    if (this.props) {
      console.log("==> REFERRAL: " + this.props.referral);
      console.log(this.props.location);
      referralName = this.props.referral;
      if (this.props.location && this.props.location.search) {
        const values = queryString.parse(this.props.location.search);
        console.log("==> REFERRAL query: " + values.referral);
        referralName = values.referral;
      }
    }

    console.log("home componentDidMount");
    var userCaptured = sessionStorage.getItem("userCaptured");
    if (!userCaptured || userCaptured == "") {
      var sessionId = SessionTracker.getSessionID();
      this.props.guidanceActions
        .captureNewUserSession(sessionId, referralName)
        .then(resp => {
          console.log("captureNewUserSession:" + resp);
          sessionStorage.setItem("userCaptured", "true");
        })
        .catch(error => {
          //
        });
    } else {
      this.props.guidanceActions.resetFeverRecommendations(referralName);
    }
  }

  componentWillReceiveProps(nextProps) {
    // console.log('home componentWillReceiveProps')
  }

  componentWillUpdate(nextProps) {
    // console.log('home componentWillUpdate')
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('home componentDidUpdate')
  }

  updateTemperature = e => {
    this.setState({ temperature: e.target.value });
  };

  updateTemperatureUnit = e => {
    this.setState({ temperatureUnit: e.target.value });
  };

  getStartedFeverRecommendation = () => {
    this.props.guidanceActions.storeTemperature(
      this.state.temperature,
      this.state.temperatureUnit
    );
    this.props.history.push("/checkfever");
  };

  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.getStartedFeverRecommendation();
    }
  };

  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }
  signupSuccessClick = () => {
    this.setState({ signupSuccess: true });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal14}
          toggle={() => this.toggle(14)}
          size="lg"
          centered
        >
          <ModalHeader className="pr-0" toggle={() => this.toggle(14)} />
          <ModalBody className="p-0">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/XQjoR5fKY5w"
                allowfullscreen
              />
            </div>
          </ModalBody>
        </Modal>
        <Navbar />
        <div className="banner-bg d-flex align-items-center">
          <div className="container">
            <div className="overlay" />
            <div className="row">
              <div className="col">
                <h2 className="mb-0">
                  <FormattedMessage
                    id="Home.mainMessage1"
                    defaultMessage="Is this fever serious?"
                  />
                  <br /> 
                  <FormattedMessage
                    id="Home.mainMessage2"
                    defaultMessage="Should you see a doctor?"
                  />
                </h2>
                <h4 className="mt-3 mb-4 pb-2">
                  <FormattedMessage
                    id="Home.mainMessage3"
                    defaultMessage="Receive immediate guidance from Boston Children’s Hospital"
                  />
                </h4>
                <FormInline className="mobile-inline large">
                  <Input
                    label="Enter temperature"
                    className="input-large input-w-13 input-rounded input-white"
                    name="temperature"
                    type="number"
                    onKeyDown={this.onKeyDown}
                    onChange={this.updateTemperature}
                  />

                  <div className="ml-2 switch">
                    <input
                      type="radio"
                      className="switch-input"
                      name="temperatureUnit"
                      value="F"
                      id="temperatureUnit-f"
                      defaultChecked
                      onChange={this.updateTemperatureUnit}
                    />
                    <label
                      htmlFor="temperatureUnit-f"
                      className="switch-label switch-label-off"
                    >
                      &#8457;
                    </label>
                    <input
                      type="radio"
                      className="switch-input"
                      name="temperatureUnit"
                      value="C"
                      id="temperatureUnit-c"
                      onChange={this.updateTemperatureUnit}
                    />
                    <label
                      htmlFor="temperatureUnit-c"
                      className="switch-label switch-label-on"
                    >
                      &#8451;
                    </label>
                    <span className="switch-selection" />
                  </div>

                  <Button
                    rounded
                    color="primary"
                    className="get-started ml-sm-3 btn-large mt-3 mt-sm-0"
                    onClick={this.getStartedFeverRecommendation}
                  >
                    <FormattedMessage
                      id="Action.GetStarted"
                      defaultMessage="Get started"
                    />
                  </Button>
                </FormInline>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper pt-4 pb-4">
          <div className="container">
            <div className="row">
              <div className="col-xs-1 col-sm-4 text-center">
                <img src={bchlogo} className="logo-list" alt="logo" />
              </div>
              <div className="col-xs-1 col-sm-4 text-center mt-4 mb-4 mt-sm-0 mb-sm-0">
                <img src={hmslogo} className="logo-list" alt="logo" />
              </div>
              <div className="col-xs-1 col-sm-4 text-center">
                <img src={hmlogo} className="logo-list" alt="logo" />
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper light pb-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3 mt-5">
                  <FormattedMessage
                    id="Home.HowItWorks.Title"
                    defaultMessage="How it works"
                  />
                </h3>
              </div>
            </div>
            <div className="row no-gutters">
              <div className="col-md-4 col-sm-12">
                <img src={step1} className="img-fluid" alt="logo" />
                <h4 className="mt-3 mb-2 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step1.Title"
                    defaultMessage="1. Take temperature"
                  />
                </h4>
                <p className="mb-0 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step1.Description"
                    defaultMessage="Take the temperature of the person who is not feeling well."
                  />
                </p>
              </div>
              <div className="col-md-4 col-sm-12 mt-3 mb-3 mt-md-0 mb-md-0 pt-3 pb-3 pt-md-0 pb-md-0">
                <img src={step2} className="img-fluid" alt="logo" />
                <h4 className="mt-3 mb-2 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step2.Title"
                    defaultMessage="2. Answer 5 simple questions"
                  />
                </h4>
                <p className="mb-0 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step2.Description"
                    defaultMessage="Provide a little info including age, weight, and symptoms."
                  />
                </p>
              </div>
              <div className="col-md-4 col-sm-12">
                <img src={step3} className="img-fluid" alt="logo" />
                <h4 className="mt-3 mb-2 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step3.Title"
                    defaultMessage="3. Get immediate guidance"
                  />
                </h4>
                <p className="mb-0 mr-3 ml-3">
                  <FormattedMessage
                    id="Home.HowItWorks.Step3.Description"
                    defaultMessage="Receive instant online guidance from Boston Children's Hospital."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="banner-bg d-flex align-items-center dose-calc-banner">
          <div className="container">
            <div className="overlay" />
            <div className="row no-gutters">
              <div className="col col-lg-5 col-md-8">
                <h2 className="mb-4">
                  <FormattedMessage
                    id="Home.Treatment.Title"
                    defaultMessage="Unsure how to treat the fever?"
                  />
                </h2>
                <h4 className="mt-0 mb-4">
                  <FormattedMessage
                    id="Home.Treatment.Description"
                    defaultMessage="Use our dose calculator to get the correct amount of Tylenol or Ibuprofen."
                  />
                </h4>
                <span className="p0 btn btn-primary btn-rounded Ripple-parent btn-large">
                  <DoseCalculator showPatientWeightUnitOption="true" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper light">
          <div className="container">
            <div className="row no-gutters pb-5 pb-lg-0">
              <div className="col-12 col-lg-6 pt-6 pb-lg-6 pb-5">
                <h2 className="light mb-4 pr-4 mr-2">
                  <FormattedMessage
                    id="Home.iThermonitor.Title"
                    defaultMessage="iThermonitor"
                  />
                </h2>
                <h4 className="mb-4 pr-4 mr-2">
                  <FormattedMessage
                    id="Home.iThermonitor.SubTitle"
                    defaultMessage="Get the iThermonitor for real-time temperature monitoring and immediate online advice from Boston Children’s Hospital."
                  />
                </h4>
                <p className="mb-4 pr-4 mr-2">
                  <FormattedMessage
                    id="Home.iThermonitor.Description"
                    defaultMessage="Thermia receives a child's temperature reading directly through the iThermonitor, an FDA-approved, patch-like wearable thermometer that is worn under the arm. When iThermonitor detects a fever, parents are notified and can access Thermia via the web or a mobile app."
                  />
                </p>
                <Button
                  rounded
                  color="primary btn-med-width"
                  className="btn-large pr-4 mr-sm-4 mr-2"
                  href="ithermonitor"
                >
                  <FormattedMessage
                    id="Home.iThermonitor.LearnMore"
                    defaultMessage="Learn more"
                  />
                </Button>
                <Button
                  rounded
                  color="primary blue btn-med-width"
                  className="btn-large"
                  target="_blank"
                  href="https://www.amazon.com/FridaBaby-010-Fridababy-FeverFrida-iThermonitor/dp/B01644ODF8/ref=sr_1_1?ie=UTF8&qid=1454011513&sr=8-1&keywords=feverfrida"
                >
                  <FormattedMessage
                    id="Home.iThermonitor.Buy"
                    defaultMessage="Buy"
                  />
                </Button>
              </div>
              <div className="banner-bg ithermo-banner col-lg-6 col-12 d-flex align-items-center justify-content-center">
                <div className="overlay x-light" />
                <span
                  className="circle-wrapper"
                  onClick={() => this.toggle(14)}
                >
                  <Fa className="text-primary ml-2" icon="play" size="3x" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper pb-5 pt-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3">
                  <FormattedMessage
                    id="Home.Share.Tile"
                    defaultMessage="Share Thermia with friends"
                  />
                </h3>
                <div className="">
                  <a href="https://www.facebook.com/sharer/sharer.php?u=http%3A//thermia.io/">
                    <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                      <Fa className="text-white" icon="facebook" size="lg" />
                    </span>
                  </a>
                  <a href="https://twitter.com/home?status=Is%20this%20fever%20serious?%20Should%20you%20see%20a%20doctor?%0Ahttp%3A//thermia.io/">
                    {" "}
                    <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                      <Fa className="text-white" icon="twitter" size="lg" />
                    </span>
                  </a>
                  <a href="mailto:?&subject=Is this fever serious&body=Is%20this%20fever%20serious?%0AShould%20you%20see%20a%20doctor?%0AReceive%20immediate%20guidance%20from%20Boston%20Children%E2%80%99s%20Hospital%0Ahttp%3A//thermia.io/">
                    <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                      <Fa className="text-white" icon="envelope" size="lg" />
                    </span>
                  </a>
                  <a href="https://www.linkedin.com/shareArticle?mini=true&url=http%3A//thermia.io/&title=&summary=Is%20this%20fever%20serious?%20Should%20you%20see%20a%20doctor?%0Ahttp%3A//thermia.io/&source=">
                    {" "}
                    <span className="circle-wrapper med-circle dark ml-2 mr-2 ml-sm-4 mr-sm-4">
                      <Fa className="text-white" icon="linkedin" size="lg" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper pb-6 pt-6 light">
          <div className="container">
            <div className="row no-gutters">
              {this.state.signupSuccess == false && (
                <div className="col col-lg-10 offset-lg-1 text-center">
                  <h2 className="light mb-4 pb-2">
                    <FormattedMessage
                      id="Home.MailingList.Message"
                      defaultMessage="Join our mailing list to receive seasonal newsletters and health tips"
                    />
                  </h2>
                  <form className="mobile-inline row no-gutters large white-bg justify-content-center">
                    <div className="col-12 col-sm-6">
                      <Input
                        label="Your email address"
                        className="input-large input-rounded"
                      />
                    </div>
                    <Button
                      rounded
                      color="primary"
                      onClick={this.signupSuccessClick}
                      className="ml-0 mt-3 mt-sm-0 ml-sm-4 btn-large"
                    >
                      <FormattedMessage
                        id="Home.MailingList.SignUp"
                        defaultMessage="Sign up"
                      />
                    </Button>
                  </form>
                </div>
              )}
              {this.state.signupSuccess == true && (
                <div className="col col-lg-10 offset-lg-1 text-center">
                  <h2 className="light mb-0 pb-0">
                    <FormattedMessage
                      id="Home.MailingList.SignUp.Success"
                      defaultMessage="You are signed up. Thank you for subscribing!"
                    />
                  </h2>
                </div>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default Home;

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
