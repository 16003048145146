import React, { Component } from "react";
import { Card, CardBody, CardText } from "mdbreact";
import { FormattedMessage } from "react-intl";
class Doses extends Component {
  renderMedicationDosing(recommendation) {
    return (
      <Card key={recommendation.name}>
        <CardBody>
          <h4 className="secondary mb-1">
            {recommendation.name} (
            {recommendation.brand_names &&
              recommendation.brand_names.map((brand_name, bindex) => {
                return (
                  <span key={"b-" + bindex}>
                    {bindex > 0 ? ", " + brand_name : brand_name}
                  </span>
                );
              })}
            )
          </h4>
          {recommendation.doseAmount != null 
            && recommendation.doseAmount > 0
            &&
            <h4 className="secondary text-green mb-1">
              {recommendation.doseAmount} {recommendation.dose_unit}
            </h4>
          }
          {recommendation.doseAmount != null 
            && recommendation.doseAmount > 0
            &&
            <CardText className="mb-2">
              <FormattedMessage id="Doses.Text1" defaultMessage="give every" />{" "}
              {recommendation.interval_minimum}{" "}
              <FormattedMessage id="Doses.Text2" defaultMessage="to" />{" "}
              {recommendation.interval_maximum} {recommendation.interval_unit}
            </CardText>
          }
          <CardText className="red-text">
            {recommendation.warning}
          </CardText>
          {/* <CardText><small>* If under 6 months consult a physician</small></CardText> */}
        </CardBody>
      </Card>
    );
  }

  render() {
    return (
      <div className="d-flex flex-wrap">
        {this.props.dosingRecommendations &&
          this.props.dosingRecommendations.medication_dosing &&
          this.props.dosingRecommendations.medication_dosing.length > 0 &&
          this.renderMedicationDosing(
            this.props.dosingRecommendations.medication_dosing[0]
          )}
        {this.props.dosingRecommendations &&
          this.props.dosingRecommendations.medication_dosing && (
            <div className="no-side-margin-modal ml-auto mr-auto align-self-center ml-md-2 mr-md-2 ml-lg-4 mr-lg-4 text-center">
              <h3 className="or">
                <FormattedMessage id="Doses.Text3" defaultMessage="or" />
              </h3>
            </div>
          )}
        {this.props.dosingRecommendations &&
          this.props.dosingRecommendations.medication_dosing &&
          this.props.dosingRecommendations.medication_dosing.length > 1 &&
          this.renderMedicationDosing(
            this.props.dosingRecommendations.medication_dosing[1]
          )}
        {this.props.dosingRecommendations && (
          <div className="no-side-margin-modal red-text">
            <p className="mt-4">{this.props.dosingRecommendations.calc_info}</p>
          </div>
        )}
        {/* <div className="no-side-margin-modal ml-auto mr-auto align-self-center ml-md-2 mr-md-2 ml-lg-4 mr-lg-4 text-center">
		  <h3 className="or">or</h3>
		</div> */}
        {/* <Card>
          <CardBody>
            <h4 className="secondary mb-1">Acetaminophen (Tylenol)</h4>
            <h4 className="secondary text-green mb-1">160 mg</h4>
            <CardText className="mb-2">give every 4 to 6 hours</CardText>
            <CardText><small>* If under 2 years consult a physician</small></CardText>
          </CardBody>
        </Card> */}
      </div>
    );
  }
}

export default Doses;
