import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FormattedMessage } from "react-intl";
class About extends Component {
  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content">
          <div className="container pt-5 pb-5 mb-5">
            <div className="row">
              <div className="col text-center col-8 mr-auto ml-auto">
                <h3 className="text-center mb-4">
                  <FormattedMessage
                    id="Contact.Section1.Title"
                    defaultMessage="Contact us"
                  />
                </h3>
                <p>
                  <FormattedMessage
                    id="Contact.Section1.Description"
                    defaultMessage="Please send us an email at {link} with any questions."
                    values={{
                      link: (
                        <a href="mailto:info@thermia.io" className="link">
                          info@thermia.io
                        </a>
                      )
                    }}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default About;
