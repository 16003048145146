import React, { Component } from "react";
import {
  Fa,
  Button,
  FormInline,
  Input,
  Modal,
  ModalHeader,
  ModalBody
} from "mdbreact";
import hospitalCircle from "../images/hospital-circle.svg";
import bluetoothCircle from "../images/bluetooth-circle.svg";
import alarmCircle from "../images/alarm-circle.svg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FormattedMessage } from "react-intl";
class Thermonitor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal14: false
    };
  }
  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  render() {
    return (
      <div className="secondary">
        <Modal
          isOpen={this.state.modal14}
          toggle={() => this.toggle(14)}
          size="lg"
          centered
        >
          <ModalHeader className="pr-0" toggle={() => this.toggle(14)} />
          <ModalBody className="p-0">
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                src="https://www.youtube.com/embed/XQjoR5fKY5w"
                allowfullscreen
              />
            </div>
          </ModalBody>
        </Modal>

        <Navbar />
        <div className="secondary-content banner-bg ithermo-banner d-flex align-items-center">
          <div className="container">
            <div className="overlay" />
            <div className="row">
              <div className="col-sm-12 col-md-6 order-2 order-md-1">
                <h2 className="mb-3 mt-3 mt-md-0">
                  <FormattedMessage
                    id="Thermonitor.Section1.Title"
                    defaultMessage="iThermonitor"
                  />
                </h2>
                <h4 className="mb-4">
                  <FormattedMessage
                    id="Thermonitor.Section1.Text"
                    defaultMessage="A wearable thermometer with mobile app makes it easier to manage children’s fever. Continuous body temperature monitoring brings you peace of mind.
		"
                  />
                </h4>
                <Button
                  rounded
                  color="primary btn-med-width"
                  className="btn-large"
                  target="_blank"
                  href="https://www.amazon.com/FridaBaby-010-Fridababy-FeverFrida-iThermonitor/dp/B01644ODF8/ref=sr_1_1?ie=UTF8&qid=1454011513&sr=8-1&keywords=feverfrida"
                >
                  <FormattedMessage
                    id="Thermonitor.Section1.Button"
                    defaultMessage="Buy"
                  />
                </Button>
              </div>
              <div className="order-md-2 order-1 col-sm-12 col-md-6 d-flex align-items-center justify-content-center">
                <span
                  className="circle-wrapper"
                  onClick={() => this.toggle(14)}
                >
                  <Fa className="text-primary ml-2" icon="play" size="3x" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="wrapper light">
          <div className="container">
            <div className="row no-gutters">
              <div className="col-sm-12 col-md-6 pt-md-6 pb-md-6 pt-5 pb-3">
                <h2 className="light mb-4">
                  <FormattedMessage
                    id="Thermonitor.Section2.Title"
                    defaultMessage="Features"
                  />
                </h2>
                <ul className="large">
                  <li>
                    <FormattedMessage
                      id="Thermonitor.Section2.List1.Item1"
                      defaultMessage="High temperature alarm"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Thermonitor.Section2.List1.Item2"
                      defaultMessage="Remote monitoring"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Thermonitor.Section2.List1.Item3"
                      defaultMessage="Fever tips from Boston Children's Hospital"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Thermonitor.Section2.List1.Item4"
                      defaultMessage="6.55mm thin for comfort while wearing"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Thermonitor.Section2.List1.Item5"
                      defaultMessage="Within .05 ℃ for accuracy"
                    />
                  </li>
                </ul>
              </div>
              <div className="col-sm-12 col-md-6 banner-bg ithermo-iphone-banner" />
            </div>
          </div>
        </div>
        <div className="wrapper pb-5">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3 mt-5">
                  <FormattedMessage
                    id="Thermonitor.Section3.Title"
                    defaultMessage="How it works"
                  />
                </h3>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm-12 col-md-4 mb-4 mb-md-0">
                <img
                  src={bluetoothCircle}
                  className="h72"
                  alt="Bluetooth icon"
                />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column1.Title"
                    defaultMessage="Continuous monitoring"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column1.Description"
                    defaultMessage="The smart thermometer checks on child every four seconds (day and night), thanks to the tiny Bluetooth monitor and patch placed under the arm."
                  />
                </p>
              </div>
              <div className="col-sm-12 col-md-4 mb-4 mb-md-0">
                <img src={alarmCircle} alt="Alarm icon" className="h72" />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column2.Title"
                    defaultMessage="Alerts and reminders"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column2.Description"
                    defaultMessage="Get alerts when body temp rises, dose reminders and data for visits to the pediatrician — all through our very smart thermometer app!"
                  />
                </p>
              </div>
              <div className="col col-sm-12 col-md-4">
                <img src={hospitalCircle} className="h72" alt="Hospital icon" />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column3.Title"
                    defaultMessage="Thermia integration"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="Thermonitor.Section3.Column3.Description"
                    defaultMessage="The app provides fever treatment tips and medication dosing recommendations from Boston Children’s Hospital."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Thermonitor;
