import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import educateCircle from "../images/educate-circle.svg";
import adviseCircle from "../images/advise-circle.svg";
import trackCircle from "../images/track-circle.svg";
import { FormattedMessage } from "react-intl";
class About extends Component {
  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content banner-bg d-flex align-items-center about-banner">
          <div className="container">
            <div className="overlay" />
            <div className="row">
              <div className="col-sm-12 col-md-8">
                <h2 className="mb-3 mt-3 mt-md-0">
                  <FormattedMessage
                    id="About.mainMessage1"
                    defaultMessage="Thermia is a decision support framework for fevers and febrile illnesses"
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper pb-5 light">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3 mt-5">
                  <FormattedMessage
                    id="About.Section2.Title"
                    defaultMessage="Our Goals"
                  />
                </h3>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-sm-12 col-md-4 mb-4 mb-md-0">
                <img src={educateCircle} className="h72" alt="Bluetooth icon" />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="About.Section2.Column1.Title"
                    defaultMessage="Educate"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="About.Section2.Column1.Description"
                    defaultMessage="Educate parents about potential underlying causes of symptoms."
                  />
                </p>
              </div>
              <div className="col-sm-12 col-md-4 mb-4 mb-md-0">
                <img src={adviseCircle} alt="Alarm icon" className="h72" />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="About.Section2.Column2.Title"
                    defaultMessage="Advise"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="About.Section2.Column2.Description"
                    defaultMessage="Provide information on supportive treatment that parents can deliver at home."
                  />
                </p>
              </div>
              <div className="col col-sm-12 col-md-4">
                <img src={trackCircle} className="h72" alt="Hospital icon" />
                <h4 className="mb-3 mt-3">
                  <FormattedMessage
                    id="About.Section2.Column3.Title"
                    defaultMessage="Track disease"
                  />
                </h4>
                <p className="mb-0">
                  <FormattedMessage
                    id="About.Section2.Column3.Description"
                    defaultMessage="Use anonymized data to track disease and better understand population health."
                  />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper">
          <div className="container">
            <div className="row no-gutters text-center">
              <div className="col-sm-12 pt-md-6 pb-md-6 pt-5 pb-3">
                <h2 className="light">
                  <FormattedMessage
                    id="About.Section3.Title"
                    defaultMessage="Thermia was developed at Boston Children's Hospital and is based on current clinical guidelines."
                  />
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div className="wrapper pb-5 light">
          <div className="container">
            <div className="row no-gutters">
              <div className="col text-center">
                <h3 className="mb-3 pb-3 mt-5">
                  <FormattedMessage
                    id="About.Section4.Title"
                    defaultMessage="Benefits"
                  />
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-md-8 offset-md-2 mb-4 mb-md-0">
                <ul className="large">
                  <li>
                    <FormattedMessage
                      id="About.Section4.ListItem1"
                      defaultMessage="Contribution to population health biosurveillance"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="About.Section4.ListItem2"
                      defaultMessage="Treatment recommendations when appropriate"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="About.Section4.ListItem3"
                      defaultMessage="Education about symptoms from Boston Children's Hospital"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="About.Section4.ListItem3"
                      defaultMessage="Optimize efficient use of medical resources, with a
                    potential to lower costs"
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

export default About;
