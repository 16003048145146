import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ModalDiarrhea extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalDiarrhea.P1"
            defaultMessage="The most common cause of diarrhea is viral infection. Diarrhea is rarely life-threatening, but can lead to dehydration which may become serious."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalDiarrhea.P2"
            defaultMessage="If your child experiences any of the following, contact your pediatrician or primary care provider:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalDiarrhea.List1Item1"
              defaultMessage="Bloody diarrhea"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalDiarrhea.List1Item2"
              defaultMessage="Refusal to eat or drink for more than a few hours"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalDiarrhea.List1Item3"
              defaultMessage="Dehydration (dry mouth, no tears when crying, decreased urination, and/or sunken eyes)"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalDiarrhea.List1Item4"
              defaultMessage="Severe abdominal pain"
            />
          </li>
        </ul>
        <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
        <p>
          <FormattedMessage
            id="ModalDiarrhea.P3"
            defaultMessage="As long as the child is not dehydrated, they should continue to eat a normal diet. Avoid foods that are high in fat or sugar because they may be harder to digest. Over the counter antidiarrheal medicines are not recommended for children."
          />
        </p>
        <p>
          <FormattedMessage
            id="Modals.TreatmentOptions.MoreInfo"
            defaultMessage="For more information, see {link}"
            values={{
              link: (
                <a
                  className="link"
                  href="http://www.childrenshospital.org/conditions-and-treatments/conditions/d/diarrhea"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="Modals.TreatmentOptions.MoreInfo.Link"
                    defaultMessage="Boston Children's Hospital Treatment Options"
                  />
                </a>
              )
            }}
          />
        </p>
      </div>
    );
  }
}

export default ModalDiarrhea;
