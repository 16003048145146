import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import {
  TabPane,
  TabContent,
  Nav,
  NavItem,
  NavLink,
  Fa,
  Tooltip,
  MDBSelect,
  MDBInput
} from "mdbreact";
import classnames from "classnames";
import Results from "./Results";
import SymptomRash from "./SymptomRash";
import SymptomSoreThroat from "./SymptomSoreThroat";
import SymptomCough from "./SymptomCough";
import SymptomShortBreath from "./SymptomShortBreath";
import SymptomHeadache from "./SymptomHeadache";
import SymptomVomiting from "./SymptomVomiting";
import SymptomDiarrhea from "./SymptomDiarrhea";
import SymptomFatigue from "./SymptomFatigue";
import yes from "../images/yes.svg";
import no from "../images/no.svg";
import idk from "../images/idk.svg";
import * as GuidanceActions from "../actions/guidance-actions";

class ProgressTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItemPills: "1",
      completeItemPills: "",
      allSymptoms: [
        { id: "rash", value: <SymptomRash /> },
        { id: "cough", value: <SymptomCough /> },
        { id: "fatigue", value: <SymptomFatigue /> },
        { id: "diarrhea", value: <SymptomDiarrhea /> },
        { id: "vomiting", value: <SymptomVomiting /> },
        { id: "short breath", value: <SymptomShortBreath /> },
        { id: "headache", value: <SymptomHeadache /> },
        { id: "sore throat", value: <SymptomSoreThroat /> }
      ],
      selectedSymptoms: {},
      chronicQuestionResponse: "",
      chinaVisitQuestionResponse: "",
      immunizationQuestionResponse: "",
      followupCheck: "",
      homeRemedyTreatment: "",
      takingMedicationFlag: "",
      medications_list: "",
      locations_visited: "",
      length_of_stay: "",
      purpose_of_visit: "",
      contated_number_of_people: "",
      comparison_to_before: "",
      awareVirusOutbreakFlag: "",
      diagnosednCovFlag: "",
      knowSomeOneDiagnosedFlag: "",
      travelledInPastTwoWeeksFlag: "",
    };
    this.selectSymptomItem.bind(this);
    this.selectChronicQuestionItem = this.selectChronicQuestionItem.bind(this);
    this.selectChinaVisit = this.selectChinaVisit.bind(this);
    this.selectImmunizationQuestionItem = this.selectImmunizationQuestionItem.bind(
      this
    );
    this.selectFollowupCheckItem = this.selectFollowupCheckItem.bind(this);
    this.renderItem = this.renderItem.bind(this);
    this.togglePills = this.togglePills.bind(this);
    this.set_awareVirusOutbreak = this.set_awareVirusOutbreak.bind(this);
    // this.set_comparePercentage = this.set_comparePercentage.bind(this);
    // this.set_contactInPastDay = this.set_contactInPastDay.bind(this);
    this.set_diagnosednCov = this.set_diagnosednCov.bind(this);
    this.set_knowSomeOneDiagnosed = this.set_knowSomeOneDiagnosed.bind(this);
    // this.set_lengthofStay = this.set_lengthofStay.bind(this);
    // this.set_locationsList = this.set_locationsList.bind(this);
    // this.set_medicationsList = this.set_medicationsList.bind(this);
    // this.set_purposeOfVisit = this.set_purposeOfVisit.bind(this);
    this.set_takingMedication = this.set_takingMedication.bind(this);
    // this.set_treatingSymptoms = this.set_treatingSymptoms.bind(this);
    this.set_travelInPastTwoWeeks = this.set_travelInPastTwoWeeks.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.feverRecommendationNavigationIndex == 1) {
      this.setState({
        selectedSymptoms: []
      });
    }
  }

  selectSymptomItem(el) {
    // console.log(el)
    var selected = this.state.selectedSymptoms;
    selected[el] = !selected[el];
    this.setState({ selectedSymptoms: selected });
    if (selected[el]) {
      // console.log('symtom selected')
      this.props.selectSymptom(el);
    } else {
      // console.log('symtom unselected')
      this.props.unselectSymptom(el);
    }
  }

  selectChronicQuestionItem(val) {
    this.setState({ chronicQuestionResponse: val });
    this.props.setChronicConditionFlag(val);
  }

  selectChinaVisit(val) {
    this.setState({ chinaVisitQuestionResponse: val });
    this.props.setChinaVisitFlag(val);
  }

  selectImmunizationQuestionItem(val) {
    this.setState({ immunizationQuestionResponse: val });
    this.props.setImmunizationFlag(val);
  }

  selectFollowupCheckItem(val) {
    this.setState({ followupCheck: val });
    this.props.setFollowupCheckFlag(val);
  }


  //Following set functions for 

  // set_treatingSymptoms(e) {
  //   this.setState({ homeRemedyTreatment: e.target.value })
  //   this.props.setTreatingSymptoms(e.target.value);
  // }

  set_takingMedication(e) {
    this.setState({ takingMedicationFlag: e.target.value })
    this.props.setTakingMedication(e.target.value)
  }



  set_travelInPastTwoWeeks(e) {
    this.setState({ travelledInPastTwoWeeksFlag: e.target.value })
    this.props.setTravelledInPastTwoWeeks(e.target.value)
  }
  // set_medicationsList(e) {
  //   this.setState({ medications_list: e.target.value })
  //   this.props.setMedicationsList(e.target.value)
  // }

  // set_locationsList(e) {
  //   this.setState({ locations_visited: e.target.value })
  //   this.props.setLocationsList(e.target.value)
  // }

  // set_lengthofStay(e) {
  //   this.setState({ length_of_stay: e.target.value })
  //   this.props.setLengthOfStay(e.target.value)
  // }

  // set_purposeOfVisit(e) {
  //   this.setState({ purpose_of_visit: e.target.value })
  //   this.props.setPurposeOfVisit(e.target.value)
  // }

  // set_contactInPastDay(e) {
  //   this.setState({ contated_number_of_people: e.target.value })
  //   this.props.setContactInPastDay(e.target.value)
  // }

  // set_comparePercentage(e) {
  //   this.setState({ comparison_to_before: e.target.value })
  //   this.props.setComparePercentage(e.target.value)
  // }

  set_awareVirusOutbreak(e) {
    this.setState({ awareVirusOutbreakFlag: e.target.value })
    this.props.setAwareVirusOutbreak(e.target.value)
  }

  set_diagnosednCov(e) {
    this.setState({ diagnosednCovFlag: e.target.value })
    this.props.setDiagnosednCov(e.target.value)
  }

  set_knowSomeOneDiagnosed(e) {
    this.setState({ knowSomeOneDiagnosedFlag: e.target.value })
    this.props.setKnowSomeoneDianosed(e.target.value)
  }


  renderItem(el) {
    var className = this.state.selectedSymptoms[el.id]
      ? "selected-symptom"
      : "symptom-group-wrapper";
    var onClick = this.selectSymptomItem.bind(this, el.id);
    return (
      <span key={el.id} className={className} onClick={onClick}>
        {el.value}
      </span>
    );
  }

  togglePills(tab) {
    if (this.state.activePills !== tab) {
      this.setState({
        activeItemPills: tab
      });
    }
  }


  changeHandler = event => {
    console.log("Change Handler --> ", event.target)
    this.setState({ [event.target.name]: event.target.value });
  };
  render() {
    return (
      <Router>
        <div className="progress-tabs">
          <Nav pills className="nav-justified">
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: this.props.feverRecommendationNavigationIndex === 1,
                  complete: this.props.feverRecommendationNavigationIndex >= 2
                })}
                onClick={() => {
                  this.togglePills("1");
                }}
              >
                <span className="number">1.</span>
                <span className="hidden-sm-down">
                  <FormattedMessage
                    id="ProgressTabs.Tab1.Title"
                    defaultMessage="Symptoms"
                  />
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: this.props.feverRecommendationNavigationIndex === 2,
                  complete: this.props.feverRecommendationNavigationIndex >= 3
                })}
                onClick={() => {
                  //this.togglePills("2");
                }}
              >
                <span className="number">2.</span>
                <span className="hidden-sm-down">
                  <FormattedMessage
                    id="ProgressTabs.Tab2.Title"
                    defaultMessage="Patient"
                  />
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: this.props.feverRecommendationNavigationIndex === 3,
                  complete: this.props.feverRecommendationNavigationIndex >= 4
                })}
                onClick={() => {
                  //this.togglePills("3");
                }}
              >
                <span className="number">3.</span>
                <span className="hidden-sm-down">
                  <FormattedMessage
                    id="ProgressTabs.Tab3.Title"
                    defaultMessage="Additional details"
                  />
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                to="#"
                className={classnames({
                  active: this.props.feverRecommendationNavigationIndex === 4
                })}
                onClick={() => {
                  //this.togglePills("4");
                }}
              >
                <span className="number">4.</span>
                <span className="hidden-sm-down">
                  <FormattedMessage
                    id="ProgressTabs.Tab4.Title"
                    defaultMessage="Results"
                  />
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          {/* <TabContent activeItem={this.state.activeItemPills}> */}
          <TabContent
            activeItem={"" + this.props.feverRecommendationNavigationIndex}
          >
            <TabPane tabId="1">
              {!this.props.symptomDetails.temperature && (
                <div className="col mb-4 pb-2">
                  <h4 className="mb-3">
                    <FormattedMessage
                      id="ProgressTabs.Tab1.Q1.Title"
                      defaultMessage="What is the patient's temperature?"
                    />
                  </h4>
                  <div
                    className={
                      "justify-content-center d-flex flex-wrap " +
                      (this.props.temperatureRequiredError ? "has-error" : "")
                    }
                  >
                    <input
                      type="number"
                      id="temperature"
                      className="form-control form-control-small input-rounded input-white"
                      onChange={this.props.setTemperature}
                    />
                    <div className="switch-circle ml-2 switch green">
                      <input
                        type="radio"
                        className="switch-input"
                        name="temperatureUnit"
                        value="F"
                        id="temperatureUnit-f"
                        defaultChecked
                        onChange={this.props.setTemperatureUnit}
                      />
                      <label
                        htmlFor="temperatureUnit-f"
                        className="switch-label switch-label-off"
                      >
                        &#8457;
                      </label>
                      <input
                        type="radio"
                        className="switch-input"
                        name="temperatureUnit"
                        value="C"
                        id="temperatureUnit-c"
                        onChange={this.props.setTemperatureUnit}
                      />
                      <label
                        htmlFor="temperatureUnit-c"
                        className="switch-label switch-label-on"
                      >
                        &#8451;
                      </label>
                      <span className="switch-selection" />
                    </div>
                  </div>
                  {this.props.temperatureRequiredError && (
                    <p className="red-text small mb-0 mt-2">
                      <FormattedMessage
                        id="ProgressTabs.Tab1.Q1.Error"
                        defaultMessage="Please enter the patient's temperature."
                      />
                    </p>
                  )}
                </div>
              )}
              <div className="col">
                <h4 className="mb-3">
                  <FormattedMessage
                    id="ProgressTabs.Tab1.Q2.Title"
                    defaultMessage="Is the patient experiencing any other symptoms? Select all that apply."
                  />
                </h4>
              </div>
              <div className="d-flex justify-content-center flex-wrap col-lg-9 ml-auto mr-auto">
                {this.state.allSymptoms.map(this.renderItem)}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <form className="mt-3 mb-3 text-left">
                <div className="col">
                  <label>
                    <FormattedMessage
                      id="ProgressTabs.Tab2.Q1.Title"
                      defaultMessage="How old is the patient?"
                    />
                  </label>
                  <div
                    className={
                      "d-flex flex-wrap " +
                      (this.props.patientAgeRequiredError ? "has-error" : "")
                    }
                  >
                    <input
                      type="number"
                      id="patientAge"
                      className="form-control form-control-small input-rounded input-white"
                      onChange={this.props.setPatientAge}
                    />

                    <div className="ml-2 switch green">
                      <input
                        type="radio"
                        className="switch-input"
                        name="patientAgeUnit"
                        value="year"
                        id="patientAgeUnit-year"
                        defaultChecked
                        onChange={this.props.setPatientAgeUnit}
                      />
                      <label
                        htmlFor="patientAgeUnit-year"
                        className="switch-label switch-label-off"
                      >
                        <FormattedMessage
                          id="ProgressTabs.Tab2.Q1.Toggle1"
                          defaultMessage="years"
                        />
                      </label>
                      <input
                        type="radio"
                        className="switch-input"
                        name="patientAgeUnit"
                        value="month"
                        id="patientAgeUnit-month"
                        onChange={this.props.setPatientAgeUnit}
                      />
                      <label
                        htmlFor="patientAgeUnit-month"
                        className="pl-3 switch-label switch-label-on"
                      >
                        <FormattedMessage
                          id="ProgressTabs.Tab2.Q1.Toggle2"
                          defaultMessage="months"
                        />
                      </label>
                      <span className="switch-selection" />
                    </div>
                  </div>
                  {this.props.patientAgeRequiredError && (
                    <p className="red-text mb-0 mt-2 small">
                      <FormattedMessage
                        id="ProgressTabs.Tab2.Q1.Error"
                        defaultMessage="Please enter the patient's age."
                      />
                    </p>
                  )}
                  <div className="mt-4 mb-4">
                    <label>
                      <FormattedMessage
                        id="ProgressTabs.Tab2.Q2.Title"
                        defaultMessage="Does the patient have any underlying chronic conditions such as cancer or lupus?"
                      />
                    </label>
                    <div className="d-flex justify-content-left flex-wrap">
                      <span
                        className={
                          this.state.chronicQuestionResponse == "yes"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() => this.selectChronicQuestionItem("yes")}
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={yes}
                            className="symptom-icon md-icon"
                            alt="Yes"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.Yes"
                              defaultMessage="Yes"
                            />
                          </h5>
                        </div>
                      </span>
                      <span
                        className={
                          this.state.chronicQuestionResponse == "no"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() => this.selectChronicQuestionItem("no")}
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={no}
                            className="symptom-icon md-icon"
                            alt="No"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.No"
                              defaultMessage="No"
                            />
                          </h5>
                        </div>
                      </span>
                      <span
                        className={
                          this.state.chronicQuestionResponse == "donot_know"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() =>
                          this.selectChronicQuestionItem("donot_know")
                        }
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={idk}
                            className="symptom-icon md-icon"
                            alt="I don't know"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.Idk"
                              defaultMessage="I don't know"
                            />
                          </h5>
                        </div>
                      </span>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label>
                      <FormattedMessage
                        id="ProgressTabs.Tab2.Q3.Title"
                        defaultMessage="Is the patient up-to-date on all routine immunizations?"
                      />
                    </label>
                    <div className="d-flex justify-content-left flex-wrap">
                      <span
                        className={
                          this.state.immunizationQuestionResponse == "yes"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() =>
                          this.selectImmunizationQuestionItem("yes")
                        }
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={yes}
                            className="symptom-icon md-icon"
                            alt="Yes"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.Yes"
                              defaultMessage="Yes"
                            />
                          </h5>
                        </div>
                      </span>
                      <span
                        className={
                          this.state.immunizationQuestionResponse == "no"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() =>
                          this.selectImmunizationQuestionItem("no")
                        }
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={no}
                            className="symptom-icon md-icon"
                            alt="No"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.No"
                              defaultMessage="No"
                            />
                          </h5>
                        </div>
                      </span>
                      <span
                        className={
                          this.state.immunizationQuestionResponse ==
                            "donot_know"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() =>
                          this.selectImmunizationQuestionItem("donot_know")
                        }
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={idk}
                            className="symptom-icon md-icon"
                            alt="I don't know"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes.Idk"
                              defaultMessage="I don't know"
                            />
                          </h5>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </TabPane>
            <TabPane tabId="3">

              <div className="text-left row no-gutters">
                <form>
                  {/* {this.props.referral === "second" && */}
                  <div className="mt-4 mb-4">
                    <label>
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q0.Title"
                        defaultMessage="Have you had a travel in the last 14 days?"
                      />
                    </label>
                    <div className="d-flex justify-content-left flex-wrap">
                      <span
                        className={
                          this.state.chinaVisitQuestionResponse == "yes"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() => this.selectChinaVisit("yes")}
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={yes}
                            className="symptom-icon md-icon"
                            alt="Yes"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes0.Yes"
                              defaultMessage="Yes"
                            />
                          </h5>
                        </div>
                      </span>
                      <span
                        className={
                          this.state.chinaVisitQuestionResponse == "no"
                            ? "selected-symptom"
                            : "symptom-group-wrapper"
                        }
                        onClick={() => this.selectChinaVisit("no")}
                      >
                        <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                          <img
                            src={no}
                            className="symptom-icon md-icon"
                            alt="No"
                          />
                          <h5 className="mt-1 mb-0">
                            <FormattedMessage
                              id="Forms.Boxes0.No"
                              defaultMessage="No"
                            />
                          </h5>
                        </div>
                      </span>
                    </div>
                  </div>
                  {/* } */}
                  <div className="col">
                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q1.Title"
                        defaultMessage="Your email"
                      />

                      <FormattedMessage id="ProgressTabs.Tab3.Q1.Tooltip">
                        {text => (
                          <Tooltip
                            placement="bottom"
                            componentClass="tooltip-info"
                            tag="span"
                            component="button"
                            tooltipContent={text}
                          >
                            <Fa icon="question-circle-o" />
                          </Tooltip>
                        )}
                      </FormattedMessage>
                    </label>

                    <input
                      type="text"
                      id="email"
                      className="form-control input-rounded input-white"
                      onChange={this.props.setEmail}
                    />
                    <label
                      htmlFor="patientWeight"
                      className="top-align mt-4 align-self-start"
                    >
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q2.Title"
                        defaultMessage="Patient's weight"
                      />
                      <FormattedMessage id="ProgressTabs.Tab3.Q2.Tooltip">
                        {text => (
                          <Tooltip
                            placement="bottom"
                            componentClass="tooltip-info"
                            tag="span"
                            component="button"
                            tooltipContent={text}
                          >
                            <Fa icon="question-circle-o" />
                          </Tooltip>
                        )}
                      </FormattedMessage>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        id="patientWeight"
                        className="form-control form-control-small input-rounded input-white"
                        onChange={this.props.setPatientWeight}
                      />

                      <div className="ml-2 switch green">
                        <input
                          type="radio"
                          className="switch-input"
                          name="patientWeightUnitAdditional"
                          value="lb"
                          id="patientWeightUnitAdditional-lbs"
                          defaultChecked
                          onChange={this.props.setPatientWeightUnit}
                        />
                        <label
                          htmlFor="patientWeightUnitAdditional-lbs"
                          className="switch-label switch-label-off"
                        >
                          lb
                        </label>
                        <input
                          type="radio"
                          className="switch-input"
                          name="patientWeightUnitAdditional"
                          value="kg"
                          id="patientWeightUnitAdditional-kg"
                          onChange={this.props.setPatientWeightUnit}
                        />
                        <label
                          htmlFor="patientWeightUnitAdditional-kg"
                          className="switch-label switch-label-on"
                        >
                          kg
                        </label>
                        <span className="switch-selection" />
                      </div>
                    </div>
                    <label
                      htmlFor=""
                      className="mt-4 top-align align-self-start"
                    >
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q3.Title"
                        defaultMessage="Your zipcode"
                      />
                    </label>
                    <FormattedMessage id="ProgressTabs.Tab3.Q3.Tooltip">
                      {text => (
                        <Tooltip
                          placement="bottom"
                          componentClass="tooltip-info"
                          tag="span"
                          component="button"
                          tooltipContent={text}
                        >
                          <Fa icon="question-circle-o" />
                        </Tooltip>
                      )}
                    </FormattedMessage>

                    <input
                      type="number"
                      id="zipcode"
                      className="form-control form-control-small input-rounded input-white"
                      onChange={this.props.setZipcode}
                    />
                    {this.props.referral &&
                      this.props.referral != "" && (
                        <div className="mt-4 mb-4">
                          <label>
                            <FormattedMessage
                              id="ProgressTabs.Tab3.Q4.Title"
                              defaultMessage="May we contact you with any followup questions?"
                            />
                          </label>
                          <div className="d-flex justify-content-left flex-wrap">
                            <span
                              className={
                                this.state.followupCheck == "yes"
                                  ? "selected-symptom"
                                  : ""
                              }
                              onClick={() =>
                                this.selectFollowupCheckItem("yes")
                              }
                            >
                              <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                                <img
                                  src={yes}
                                  className="symptom-icon md-icon"
                                  alt="Yes"
                                />
                                <h5 className="mt-1 mb-0">
                                  <FormattedMessage
                                    id="Forms.Boxes.Yes"
                                    defaultMessage="Yes"
                                  />
                                </h5>
                              </div>
                            </span>
                            <span
                              className={
                                this.state.followupCheck == "no"
                                  ? "selected-symptom"
                                  : ""
                              }
                              onClick={() => this.selectFollowupCheckItem("no")}
                            >
                              <div className="text-center short symptom-group d-flex flex-column justify-content-center">
                                <img
                                  src={no}
                                  className="symptom-icon md-icon"
                                  alt="No"
                                />
                                <h5 className="mt-1 mb-0">
                                  <FormattedMessage
                                    id="Forms.Boxes.No"
                                    defaultMessage="No"
                                  />
                                </h5>
                              </div>
                            </span>
                          </div>
                        </div>
                      )}


                    {/* NEW ADDITIONAL QUESTIONNAIRE RELATED TO CORONA VIRUS  */}


                    {/* TREATMENT */}

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q20.Title"
                        defaultMessage="How are you treating your symptoms? (Including home remedies)"
                      />
                    </label>
                    <input type="text" id="treating_symptoms" placeholder="" className="form-control input-white" onChange={this.props.setTreatingSymptoms} />

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q21.Title"
                        defaultMessage="Are you taking any medication?"
                      />
                    </label>

                    <MDBInput
                      name="taking_meds"
                      className="form-control form-check-input"
                      onClick={this.set_takingMedication}
                      checked={
                        this.state.takingMedicationFlag === "yes"
                          ? true
                          : false
                      }
                      label="Yes"
                      type="radio"
                      id="radio3"
                      value="yes"
                    />
                    <MDBInput
                      name="taking_meds"
                      className="form-control form-check-input"
                      onClick={this.set_takingMedication}
                      checked={
                        this.state.takingMedicationFlag === "no"
                          ? true
                          : false
                      }
                      label="No"
                      type="radio"
                      id="radio4"
                      value="no"
                    />




                    {/* <input onClick={this.props.setTakingMedication} checked={this.props.state_takingMedicationFlag === "yes" ? true : false} label="Yes"
                      type="radio" id="taking-medications-yes" value="yes" />
                    <input
                      onClick={this.props.setTakingMedication}
                      checked={this.props.state_takingMedicationFlag === "no" ? true : false}
                      label="No"
                      type="radio"
                      id="taking-medications-no"
                      value="no"
                    /> */}

                    {this.state.takingMedicationFlag && this.state.takingMedicationFlag === "yes" && (
                      <span>
                        <label htmlFor="" className="top-align align-self-start">
                          <FormattedMessage
                            id="ProgressTabs.Tab3.Q22.Title"
                            defaultMessage="What Medications?"
                          />
                        </label>
                        <input type="text" id="id_medications_list" placeholder="Enter Medication list" className="form-control input-white" onChange={this.props.setMedicationsList} />
                      </span>
                    )}


                    {/* TRAVEL */}

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q23.Title"
                        defaultMessage="Which locations have you been to in the past 14 days?"
                      />

                    </label>
                    <input type="text" id="locations_list" value={this.props.state_locations_visited} className="form-control input-rounded input-white" onChange={this.props.setLocationsList} />
                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q24.Title"
                        defaultMessage="How long did you stay (days)?"
                      />

                    </label>
                    <input
                      type="number"
                      id="how_long_did_you_stay"
                      className="form-control form-control-small input-rounded input-white"
                      onChange={this.props.setLengthOfStay}
                      value={this.props.state_length_of_stay}
                    />
                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q25.Title"
                        defaultMessage="Purpose of visit"
                      />

                    </label>

                    {/* <MDBSelect
                    options={this.state.purposeOfVisit_options}
                    selected="Choose your option"
                    label="Purpose of visit"
                    color="primary"
                    className="input-white"
                  /> */}

                    <select className="input-rounded browser-default custom-select" onChange={this.props.setPurposeOfVisit} value={this.props.state_purpose_of_visit}>
                      <option value="">Choose purpose of visit</option>
                      <option value="family">Family</option>
                      <option value="tourism">Tourism</option>
                      <option value="business">Business</option>
                      <option value="other">Other</option>
                    </select>




                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q21.Title"
                        defaultMessage="Have you travelled in the last two weeks?"
                      />
                    </label>

                    <MDBInput
                      name="travel_in_past_2_weeks"
                      className="form-control form-check-input"
                      onClick={this.set_travelInPastTwoWeeks}
                      checked={
                        this.state.travelledInPastTwoWeeksFlag === "yes"
                          ? true
                          : false
                      }
                      label="Yes"
                      type="radio"
                      id="radio8"
                      value="yes"
                    />
                    <MDBInput
                      name="travel_in_past_2_weeks"
                      className="form-control form-check-input"
                      onClick={this.set_travelInPastTwoWeeks}
                      checked={
                        this.state.travelledInPastTwoWeeksFlag === "no"
                          ? true
                          : false
                      }
                      label="No"
                      type="radio"
                      id="radio7"
                      value="no"
                    />

                    {/* CONTACT */}

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q26.Title"
                        defaultMessage="What was the number of people you’ve had contact with the last 24h?"
                      />
                    </label>
                    <input
                      type="number"
                      id="contact_in_past_24_hrs"
                      className="form-control form-control-small input-rounded input-white"
                      onChange={this.props.setContactInPastDay}
                      value={this.props.state_contated_number_of_people}
                    />

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q27.Title"
                        defaultMessage="How does this number of people compare to before?"
                      />

                    </label>
                    <select className="browser-default custom-select" value={this.props.state_comparison_to_before} onChange={this.props.setComparePercentage}>
                      <option value="">Choose comparison</option>
                      <option value="0-25">0-25%</option>
                      <option value="25-50">25-50%</option>
                      <option value="50-75">50-75%</option>
                      <option value="75-200">75%-100%</option>
                      <option value="gt100"> >100% </option>
                    </select>
                    {/* nCov-2019 */}

                    <label htmlFor="" className="top-align align-self-start">
                      <FormattedMessage
                        id="ProgressTabs.Tab3.Q27.Title"
                        defaultMessage="Are you aware of the current outbreak of novel coronavirus?"
                      />

                    </label>
                    <MDBInput
                      name="virusoutbreak"
                      onClick={this.set_awareVirusOutbreak}
                      checked={this.state.awareVirusOutbreakFlag === "yes" ? true : false}
                      label="Yes"
                      type="radio"
                      id="outbreak-yes"
                      value="yes"
                    />
                    <MDBInput
                      name="virusoutbreak"
                      onClick={this.set_awareVirusOutbreak}
                      checked={this.state.awareVirusOutbreakFlag === "no" ? true : false}
                      label="No"
                      type="radio"
                      id="outbreak-no"
                      value="no"
                    />

                    {this.state.awareVirusOutbreakFlag && this.state.awareVirusOutbreakFlag === "yes" && (
                      <span>
                        <label htmlFor="" className="top-align align-self-start">

                          <FormattedMessage
                            id="ProgressTabs.Tab3.Q27.Title"
                            defaultMessage="Have you been diagnosed with having nCov-2019?"
                          />
                        </label>
                        <MDBInput
                          name="diagnosed_with_ncov"
                          onClick={this.set_diagnosednCov}
                          checked={this.state.diagnosednCovFlag === "yes" ? true : false}
                          label="Yes"
                          type="radio"
                          id="ncov-yes"
                          value="yes"
                        />
                        <MDBInput
                          name="diagnosed_with_ncov"
                          onClick={this.set_diagnosednCov}
                          checked={this.state.diagnosednCovFlag === "no" ? true : false}
                          label="No"
                          type="radio"
                          id="ncov-no"
                          value="no"
                        />
                        <label htmlFor="" className="top-align align-self-start">

                          <FormattedMessage
                            id="ProgressTabs.Tab3.Q27.Title"
                            defaultMessage="Do you know anyone who has been diagnosed with nCov-2019?"
                          />

                        </label>
                        <MDBInput
                          name="knownSomeone_with_corona"
                          onClick={this.set_knowSomeOneDiagnosed}
                          checked={this.state.knowSomeOneDiagnosedFlag === "yes" ? true : false}
                          label="Yes"
                          type="radio"
                          id="corona-yes"
                          value="yes"
                        />
                        <MDBInput
                          name="knownSomeone_with_corona"
                          onClick={this.set_knowSomeOneDiagnosed}
                          checked={this.state.knowSomeOneDiagnosedFlag === "no" ? true : false}
                          label="No"
                          type="radio"
                          id="corona-no"
                          value="no"
                        />
                      </span>
                    )}

                    {/* END */}

                  </div>
                </form>
              </div>
            </TabPane>
            <TabPane tabId="4">
              <Results />
            </TabPane>
          </TabContent>
        </div>
      </Router>
    );
  }
}

// export default ProgressTabs;

function mapStateToProps(state) {
  //console.log("mapStateToProps: "+JSON.stringify(state));
  return {
    feverRecommendationNavigationIndex:
      state.feverRecommendationsState.feverRecommendationNavigationIndex,
    symptomDetails: state.feverRecommendationsState.symptomDetails,
    temperatureRequiredError:
      state.feverRecommendationsState.temperatureRequiredError,
    patientAgeRequiredError:
      state.feverRecommendationsState.patientAgeRequiredError,
    referral: state.feverRecommendationsState.referral
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProgressTabs);
