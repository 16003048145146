import React, { Component } from "react";
import { Col } from "mdbreact";
import { FormattedMessage } from "react-intl";

class ModalHeadache extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalHeadache.P1"
            defaultMessage="Children often experience headaches as part of a cold, flu, sinus infection, or sore throat. In rare cases, a headache may be a sign of a more serious infection."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalHeadache.P2"
            defaultMessage="You should call your pediatrician or primary care provider if the headache is sudden, severe, and accompanied by any of the following:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item1"
              defaultMessage="Vomiting"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item2"
              defaultMessage="Neck pain or stiffness"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item3"
              defaultMessage="Double vision or changes in vision"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item4"
              defaultMessage="Confusion"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item5"
              defaultMessage="Loss of balance"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List1Item6"
              defaultMessage="Fever of 100.4F/ 38C or higher"
            />
          </li>
        </ul>
        <p>
          <FormattedMessage
            id="ModalHeadache.P3"
            defaultMessage="This may be a sign of a more serious infection. Do not give your child medicine before calling your doctor."
          />
        </p>
        <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
        <p>
          <FormattedMessage
            id="ModalHeadache.P4"
            defaultMessage="Most headaches in children are not caused by a serious problem. To help your child feel better, you can try the following:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalHeadache.List2Item1"
              defaultMessage="Have the child rest in a quiet dark room with a cool cloth on his or her forehead."
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List2Item2"
              defaultMessage="Encourage your child to sleep"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalHeadache.List2Item3"
              defaultMessage="Give your child pain medicine such as acetaminophen (Tylenol) or ibuprofen (Advil, Motrin). Do not use aspirin in children under 18 years old."
            />
          </li>
        </ul>
        <p>
          <FormattedMessage
            id="Modals.TreatmentOptions.MoreInfo"
            defaultMessage="For more information, see {link}"
            values={{
              link: (
                <a
                  className="link"
                  href="http://www.childrenshospital.org/conditions-and-treatments/conditions/h/headaches"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="Modals.TreatmentOptions.MoreInfo.Link"
                    defaultMessage="Boston Children's Hospital Treatment Options"
                  />
                </a>
              )
            }}
          />
        </p>
      </div>
    );
  }
}

export default ModalHeadache;
