import React, { Component } from "react";
import fatigue from "../images/symptoms/fatigue.svg";
import fatiguewhite from "../images/symptoms/fatigue-white.svg";
import { FormattedMessage } from "react-intl";

class SymptomFatigue extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={fatigue} className="symptom-icon" alt="Fatigue" />
        <img src={fatiguewhite} className="symptom-icon white-outline" alt="Fatigue" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomFatigue.Title"
            defaultMessage="Fatigue"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomFatigue;
