import React, { Component } from "react";
import { Navbar, NavbarNav, NavLink, NavItem, Fa } from "mdbreact";
import { FormattedMessage } from "react-intl";

class Footer extends Component {
  render() {
    return (
      <div className="wrapper footer">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-12 col-lg-6">
              <span className="tagline mb-lg-5">
                <FormattedMessage
                  id="Footer.Tagline"
                  defaultMessage="Thermia is simplifying fevers"
                />
              </span>
            </div>
            <div className="col-12 col-lg-6 mb-3 mb-lg-0">
              <Navbar expand="sm">
                <NavbarNav right>
                  <NavItem>
                    <NavLink className="white-text" to="/about">
                      <FormattedMessage
                        id="Footer.NavLink1"
                        defaultMessage="About"
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/contact" className="white-text">
                      <FormattedMessage
                        id="Footer.NavLink2"
                        defaultMessage="Contact"
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="white-text" to="/press">
                      <FormattedMessage
                        id="Footer.NavLink3"
                        defaultMessage="Press"
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink className="white-text" to="/privacy">
                      <FormattedMessage
                        id="Footer.NavLink4"
                        defaultMessage="Terms and Privacy"
                      />
                    </NavLink>
                  </NavItem>
                </NavbarNav>
              </Navbar>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col">
              <a target="_blank" href="https://www.facebook.com/raiing.usa">
                <Fa
                  className="text-white mr-4"
                  icon="facebook-official"
                  size="lg"
                />
              </a>
              <a target="_blank" href="https://twitter.com/thermiapp">
                <Fa className="text-white" icon="twitter" size="lg" />
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
