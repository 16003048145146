import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
class ModalShortBreath extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalShortBreath.P1"
            defaultMessage="Children may experience difficulty breathing due to nasal congestion, commonly occurring with viral infections. In some cases, difficulty breathing may be a sign of a more serious problem."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalShortBreath.P2"
            defaultMessage="If your child is under 3 months, and difficulty breathing does not improve after clearing your child's nose, contact your pediatrician or primary care provider."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalShortBreath.P3"
            defaultMessage="If your child cannot catch their breath, has blue discoloration in lips/face, or has difficulty speaking in full sentences (in older children), contact your pediatrician or primary care provider."
          />
        </p>
      </div>
    );
  }
}

export default ModalShortBreath;
