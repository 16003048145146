export const GET_DOSING_RECOMMENDATIONS_SUCCESS = 'GET_DOSING_RECOMMENDATIONS_SUCCESS';
export const GET_FEVER_RECOMMENDATIONS_SUCCESS = 'GET_FEVER_RECOMMENDATIONS_SUCCESS';
export const STORE_TEMPERATURE = 'STORE_TEMPERATURE';
export const STORE_SYMPTOMS = 'STORE_SYMPTOMS';
export const STORE_PATIENT_DETAILS = 'STORE_PATIENT_DETAILS';
export const STORE_ADDITIONAL_DETAILS = 'STORE_ADDITIONAL_DETAILS';
export const RESET_DOSING_RECOMMENDATIONS = 'RESET_DOSING_RECOMMENDATIONS';
export const RESET_FEVER_RECOMMENDATIONS = 'RESET_FEVER_RECOMMENDATIONS';
export const FEVER_RECOMMENDATION_MOVE_NEXT = 'MOVE_NEXT_FEVER_RECOMMENDATION_MOVE_NEXT';
export const FEVER_RECOMMENDATION_MOVE_BACK = 'MOVE_NEXT_FEVER_RECOMMENDATION_MOVE_BACK';
export const NEW_USER_SESSION_SUCCESS = 'NEW_USER_SESSION_SUCCESS';
export const FEVER_TEMPERATURE_REQUIRED_ERROR = 'FEVER_TEMPERATURE_REQUIRED_ERROR';
export const FEVER_PATIENT_AGE_REQUIRED_ERROR = 'FEVER_PATIENT_AGE_REQUIRED_ERROR';