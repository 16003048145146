import * as ActionTypes from '../actions/action-types';
import * as KidsMDApi from '../api/kidsmd-api'

export function getDosingRecommendationsByWeight(weight, weightUnit, sessionId){
  return function(dispatch){
    return KidsMDApi.getDosingRecommendations(null, null, weight, weightUnit, sessionId).then(recommendations => {
      dispatch({type: ActionTypes.GET_DOSING_RECOMMENDATIONS_SUCCESS, recommendations});
    }).catch(error => {
      throw(error);
    })
  }
}

export function getDosingRecommendationsByAge(age, ageUnit, sessionId){
  return function(dispatch){
    return KidsMDApi.getDosingRecommendations(age, ageUnit, null, null, sessionId).then(recommendations => {
      dispatch({type: ActionTypes.GET_DOSING_RECOMMENDATIONS_SUCCESS, recommendations});
    }).catch(error => {
      throw(error);
    })
  }
}

export function resetDosingRecommendations(){
  return function(dispatch){
    dispatch({type: ActionTypes.RESET_DOSING_RECOMMENDATIONS});
  }
}