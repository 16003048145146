import React, { Component } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { FormattedMessage } from "react-intl";

class Press extends Component {
  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content">
          <div className="container pt-5 pb-5 mb-5">
            <div className="row">
              <div className="col text-center col-8 mr-auto ml-auto">
                <h3 className="text-center mb-4">
                  <FormattedMessage
                    id="Press.Section1.Title"
                    defaultMessage="Press"
                  />
                </h3>
                <p>Coming soon!</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Press;
