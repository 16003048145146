import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
class ModalFatigue extends Component {
  render() {
    return (
      <div className="symptoms-details">
      <p><FormattedMessage
            id="ModalFatigue.P1"
            defaultMessage="Fatigue, or increased tiredness, is a common side effect of illness, as your body works to fight off the infection."
          />
		</p>
      <p><FormattedMessage
            id="ModalFatigue.P2"
            defaultMessage="If a child is extremely drowsy, so much so that it is difficult to feed or wake them, it may be a sign of a more serious illness. Contact your pediatrician or primary care provider."
          />
		</p>
		</div>
    );
  }
}

export default ModalFatigue;
