import React, { Component } from "react";
import cough from "../images/symptoms/cough.svg";
import coughwhite from "../images/symptoms/cough-white.svg";
import { FormattedMessage } from "react-intl";

class SymptomCough extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={cough} className="symptom-icon" alt="Cough" />
		<img src={coughwhite} className="symptom-icon white-outline" alt="Cough" />
        <h5 className="mt-3 mb-0">
		 <FormattedMessage
            id="SymptomCough.Title"
            defaultMessage="Cough"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomCough;
