import React, { Component } from "react";
import shortofbreath from "../images/symptoms/short-of-breath.svg";
import shortofbreathwhite from "../images/symptoms/short-of-breath-white.svg";
import { FormattedMessage } from "react-intl";
class SymptomShortBreath extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={shortofbreath} className="symptom-icon" alt="Shortness of Breath" />
        <img src={shortofbreathwhite} className="white-outline symptom-icon" alt="Shortness of Breath" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomShortBreath.Title"
            defaultMessage="Shortness of breath"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomShortBreath;
