import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import { Button, FormInline, Modal, ModalBody, ModalHeader } from "mdbreact";
import Doses from "./Doses";
import * as DosingActions from "../actions/dosing-actions";
import * as GuidanceActions from "../actions/guidance-actions";
import * as SessionTracker from "../util/session-tracker";

class DoseCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      patientWeight: 0,
      patientWeightUnit: "lb"
    };
    this.updatePatientWeight = this.updatePatientWeight.bind(this);
    this.updatePatientWeightUnit = this.updatePatientWeightUnit.bind(this);
  }

  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
    if (nr == 8) {
      //dosing calculator
      this.props.dosingActions.resetDosingRecommendations();
    }
  }

  updatePatientWeight = e => {
    console.log("weight: " + e.target.value);
    this.setState({ patientWeight: e.target.value });
  };

  updatePatientWeightUnit = e => {
    console.log("selected: " + e.target.value);
    this.setState({ patientWeightUnit: e.target.value });
  };
  onKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.lookupRecommendations();
    }
  };

  lookupRecommendations = () => {
    var sessionId = SessionTracker.getSessionID();
    this.props.dosingActions.getDosingRecommendationsByWeight(
      this.state.patientWeight,
      this.state.patientWeightUnit,
      sessionId
    );
  };

  render() {
    return (
      <div className="text-left">
        <Modal
          isOpen={this.state.modal8}
          toggle={() => this.toggle(8)}
          fullHeight
          className="med-width"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(8)}>
            <FormattedMessage
              id="DoseCalculator.Title"
              defaultMessage="Dose Calculator"
            />
          </ModalHeader>
          <ModalBody>
            <FormInline className="mb-5 d-flex flex-column mobile-inline">
              <h4 className="mb-4 dosing-calculator-label">
                <FormattedMessage
                  id="DoseCalculator.Description"
                  defaultMessage="Use the Boston Children’s Hospital dose calculator to see the correct dose of tylenol and ibuprofen."
                />
              </h4>
              <label
                htmlFor="patientWeight"
                className="top-align align-self-start dosing-calculator-label"
              >
                <FormattedMessage
                  id="DoseCalculator.PatientWeight"
                  defaultMessage="Patient's Weight"
                />
              </label>
              <div className="d-flex mr-auto flex-wrap">
                <div className="d-flex flex-wrap">
                  <input
                    type="number"
                    id="patientWeight"
                    className="input-w-10 form-control input-rounded input-white"
                    onKeyDown={this.onKeyDown}
                    onChange={this.updatePatientWeight}
                  />

                  {this.props.showPatientWeightUnitOption == "true" && (
                    <div className="ml-2 switch green">
                      <input
                        type="radio"
                        className="switch-input"
                        name="patientWeightUnit"
                        value="lb"
                        id="patientWeightUnit-lbs"
                        onChange={this.updatePatientWeightUnit.bind(this)}
                        checked={
                          this.state.patientWeightUnit &&
                          this.state.patientWeightUnit == "lb"
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor="patientWeightUnit-lbs"
                        className="switch-label switch-label-off"
                      >
                        lb
                      </label>
                      <input
                        type="radio"
                        className="switch-input"
                        name="patientWeightUnit"
                        value="kg"
                        id="patientWeightUnit-kg"
                        onChange={this.updatePatientWeightUnit.bind(this)}
                        checked={
                          this.state.patientWeightUnit &&
                          this.state.patientWeightUnit == "kg"
                            ? true
                            : false
                        }
                      />
                      <label
                        htmlFor="patientWeightUnit-kg"
                        className="switch-label switch-label-on"
                      >
                        kg
                      </label>
                      <span className="switch-selection" />
                    </div>
                  )}
                  {this.props.showPatientWeightUnitOption == "false" && (
                    <span className="text-green lh3 ml-1 bold">lbs</span>
                  )}
                </div>
                <Button
                  rounded
                  color="primary"
                  className="ml-0 ml-sm-3 mt-3 mt-sm-0 btn-sm-width"
                  onClick={this.lookupRecommendations}
                >
                  <FormattedMessage
                    id="DoseCalculator.Action.GetDose"
                    defaultMessage="Get dose"
                  />
                </Button>
              </div>
            </FormInline>
            <span className="full-width">
              <Doses dosingRecommendations={this.props.dosingRecommendations} />
            </span>
          </ModalBody>
        </Modal>
        <span className="inner-button" onClick={() => this.toggle(8)}>
          <FormattedMessage
            id="DoseCalculator.Title"
            defaultMessage="Dose Calculator"
          />
        </span>
      </div>
    );
  }
}

// export default DoseCalculator;

function mapStateToProps(state) {
  return {
    dosingRecommendations:
      state.dosingRecommendationsState.dosingRecommendations
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dosingActions: bindActionCreators(DosingActions, dispatch),
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DoseCalculator);
