import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ModalRash extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalRash.P1"
            defaultMessage="Rashes are commonly associated with viral infections. These types of rashes usually go away on their own without specific treatment."
          />
		</p>
        <p>
          <FormattedMessage
            id="ModalRash.P2"
            defaultMessage="If your child experiences any of the following, you should call your pediatrician or primary care provider:"
          />
        </p>
        <ul>
          <li>
          <FormattedMessage
            id="ModalRash.List1Item1"
            defaultMessage="Skin does not temporarily turn pale when pressed with a finger"
          />
          </li>
          <li>
          <FormattedMessage
            id="ModalRash.List1Item2"
            defaultMessage="Skin is a purple, bruise-like color"
          />
          </li>
          <li>
          <FormattedMessage
            id="ModalRash.List1Item3"
            defaultMessage="Rash appears infected, is painful or has oozing discharge"
          />
          </li>
        </ul>
          <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
		<p>
          <FormattedMessage
            id="ModalRash.P3"
            defaultMessage="Most rashes do not require treatment. If your child's rash is itchy, you can treat it with a topical antihistamine, such as Benadryl."
          />
        </p>
      </div>
    );
  }
}

export default ModalRash;
