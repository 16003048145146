import React, { Component } from "react";
import {
  SideNav,
  Navbar,
  NavbarBrand,
  Collapse,
  Container,
  Fa,
} from "mdbreact";
import NavbarItem from "./NavbarItem";

class Topnav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: false,
      isWideEnough: false,
      isLeftOpen: true
    };
  }

  // Slide out buttons event handlers
  handleToggleClickA = () => {
    this.setState({
      isLeftOpen: !this.state.isLeftOpen
    });
  };

  render() {
    return (
      <div>

        <Navbar
          fixed="top"
          expand="md"
          transparent
          scrolling
          color="white"
          dark
        >
          <Container>
            <NavbarBrand href="/">Thermia</NavbarBrand>
            <a
              className="sidenav-toggle"
              href="#!"
              onClick={this.handleToggleClickA}
            >
              <Fa icon="bars" size="lg" />
            </a>
            <Collapse isOpen={this.state.collapse} navbar>
                <NavbarItem />
            </Collapse>
          </Container>
        </Navbar>

        {/* the left SideNav: */}
        <SideNav
          hidden
          triggerOpening={this.state.isLeftOpen}
          className="main-nav"
        >
		<NavbarBrand href="/">Thermia</NavbarBrand>
            <NavbarItem />
        </SideNav>
      </div>
    );
  }
}
export default Topnav;
