import { combineReducers } from 'redux'
import DosingRecommendationsReducer from './dosingRecommendations-reducer'
import FeverRecommendationsReducer from './feverRecommendations-reducer'

const rootReducer = combineReducers({
  dosingRecommendationsState: DosingRecommendationsReducer,
  feverRecommendationsState: FeverRecommendationsReducer
})

export default rootReducer
