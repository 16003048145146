import React, { Component } from "react";
import DoseCalculator from "./DoseCalculator";
import { Button, NavbarNav, NavItem, NavLink, Fa, FormInline } from "mdbreact";
import { FormattedMessage } from "react-intl";
class NavbarItem extends Component {
  render() {
    return (
      <NavbarNav right>
        <NavItem>
          <NavLink to="/checkfever">
            <FormattedMessage
              id="NavbarItem1"
              defaultMessage="Check your fever"
            />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/ithermonitor">
            <FormattedMessage id="NavbarItem2" defaultMessage="iThermonitor" />
          </NavLink>
        </NavItem>
        <NavItem>
          <span className="nav-link">
            <DoseCalculator showPatientWeightUnitOption="true" />
          </span>
        </NavItem>
        <NavItem>
          <a className="nav-link nav-separator" href="http://thermia.io/cn">
            中文
          </a>
        </NavItem>
      </NavbarNav>
    );
  }
}

export default NavbarItem;
