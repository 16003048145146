import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
class ModalVomiting extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalVomiting.P1"
            defaultMessage="The most common cause of vomiting in children is gastroenteritis, or infection of the stomach or intestines usually caused by a virus. Most children with vomiting do not need to see a healthcare provider. Vomiting usually subsides within 24-48 hours."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalVomiting.P2"
            defaultMessage="If your child experiences any of the following, you should call your pediatrician or primary care provider:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalVomiting.List1Item1"
              defaultMessage="Green, red, or brown vomit"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalVomiting.List1Item2"
              defaultMessage="Vomiting that continues for more than 24 hours"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalVomiting.List1Item3"
              defaultMessage="Dehydration (dry mouth, no tears when crying, decreased urination, and/or sunken eyes)"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalVomiting.List1Item4"
              defaultMessage="Severe abdominal pain"
            />
          </li>
        </ul>
        <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
        <p>
          <FormattedMessage
            id="ModalVomiting.P3"
            defaultMessage="Children without dehydration can eat a normal diet, though children often have a decreased appetite. Avoid foods that are high in fat or sugar, because they may be harder to digest. Over the counter medicines for nausea or vomiting are not recommended for infants or children."
          />
        </p>
      </div>
    );
  }
}

export default ModalVomiting;
