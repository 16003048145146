import axios from 'axios';

const KIDSMD_API_URL = "https://thermia.io/kidsmd-api"
// const KIDSMD_API_URL = "https://test.thermia.io/kidsmd-api"
// const KIDSMD_API_URL = "http://localhost:4500/kidsmd-api"

export function getDosingRecommendations(age, ageUnit, weight, weightUnit, sessionId) {
  var data = "age=" + age
    + "&ageUnit=" + ageUnit
    + "&weight=" + weight
    + "&weightUnit=" + weightUnit
    + "&sessionId=" + sessionId
    + "&_" + (new Date());
  return axios.post(KIDSMD_API_URL + "/dosingRecommendations", data)
    .then(response => {
      if (response) {
        return response.data;
      } else {
        return '';
      }
    }).catch(function (error) {
      console.log(error);
      return error;
    });
}

export function getFeverRecommendations(symptomDetails, sessionId) {
  var mainSymptom = "fever"
  // console.log("symptom details kids API -> ", symptomDetails)
  var data = "symptomName=" + mainSymptom
    + "&age=" + symptomDetails.patientAge
    + "&ageUnit=" + symptomDetails.patientAgeUnit
    + "&temperature=" + symptomDetails.temperature
    + "&temperatureUnit=" + symptomDetails.temperatureUnit
    + "&chronicConditionFlag=" + symptomDetails.chronicConditionFlag
    + "&chinaVisitFlag=" + symptomDetails.chinaVisitFlag
    + "&immunizationFlag=" + symptomDetails.immunizationFlag
    + "&referral=" + symptomDetails.referral
    + "&followupCheckFlag=" + symptomDetails.followupCheckFlag
    + "&weight=" + symptomDetails.patientWeight
    + "&weightUnit=" + symptomDetails.patientWeightUnit
    + "&email=" + symptomDetails.email
    + "&zipcode=" + symptomDetails.zipcode
    + "&treatingSymptoms=" + symptomDetails.treatingSymptoms
    + "&takingMedicationFlag=" + symptomDetails.takingMedicationFlag
    + "&medications_list=" + symptomDetails.medications_list
    + "&locations_visited=" + symptomDetails.locations_visited
    + "&length_of_stay=" + symptomDetails.length_of_stay
    + "&purpose_of_visit=" + symptomDetails.purpose_of_visit
    + "&contated_number_of_people=" + symptomDetails.contated_number_of_people
    + "&comparison_to_before=" + symptomDetails.comparison_to_before
    + "&awareVirusOutbreakFlag=" + symptomDetails.awareVirusOutbreakFlag
    + "&diagnosednCovFlag=" + symptomDetails.diagnosednCovFlag
    + "&knowSomeOneDiagnosedFlag=" + symptomDetails.knowSomeOneDiagnosedFlag
    + "&travelledInPastTwoWeeksFlag=" + symptomDetails.travelledInPastTwoWeeksFlag
    + "&otherSymptoms=" + symptomDetails.symptoms
    + "&sessionId=" + sessionId
    + "&_" + (new Date());
  return axios.post(KIDSMD_API_URL + "/symptomTriage", data)
    .then(response => {
      return response.data;
    }).catch(function (error) {
      console.log(error);
      return error;
    });
}

export function captureNewUserSession(sessionId, referral) {
  var data = "sessionId=" + sessionId + "&referral=" + referral + "&_" + (new Date());
  return axios.post(KIDSMD_API_URL + "/captureUserSession", data)
    .then(response => {
      if (response) {
        return response.data;
      } else {
        return '';
      }
    }).catch(function (error) {
      console.log(error);
      return error;
    });
}
