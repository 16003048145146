import * as types from '../actions/action-types';
import initialState from './initialState';

const DosingRecommendationsReducer = function(state = initialState, action) {

  switch(action.type) {
    case types.GET_DOSING_RECOMMENDATIONS_SUCCESS:
      return Object.assign({}, state, { dosingRecommendations: action.recommendations});
    case types.RESET_DOSING_RECOMMENDATIONS:
      return Object.assign({}, state, { dosingRecommendations: {}});
  }

  return state;
}
export default DosingRecommendationsReducer
