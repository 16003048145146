import React, { Component } from "react";
import diarrhea from "../images/symptoms/diarrhea.svg";
import diarrheawhite from "../images/symptoms/diarrhea-white.svg";
import { FormattedMessage } from "react-intl";

class SymptomDiarrhea extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={diarrhea} className="symptom-icon" alt="Diarrhea" />
        <img src={diarrheawhite} className="symptom-icon white-outline" alt="Diarrhea" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomDiarrhea.Title"
            defaultMessage="Diarrhea"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomDiarrhea;
