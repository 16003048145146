import React, { Component } from "react";
import vomiting from "../images/symptoms/vomiting.svg";
import vomitingwhite from "../images/symptoms/vomiting-white.svg";
import { FormattedMessage } from "react-intl";
class Vomiting extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={vomiting} className="symptom-icon" alt="Vomiting" />
        <img src={vomitingwhite} className="white-outline symptom-icon" alt="Vomiting" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomVomiting.Title"
            defaultMessage="Vomiting"
          />
		</h5>
      </div>
    );
  }
}

export default Vomiting;
