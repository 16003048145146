import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Fa, Button, FormInline, Input } from "mdbreact";
import Navbar from "./Navbar";
import Footer from "./Footer";
import ProgressTabs from "./ProgressTabs";
import * as GuidanceActions from "../actions/guidance-actions";
import * as SessionTracker from "../util/session-tracker";
import { FormattedMessage } from "react-intl";

class CheckFever extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSymptoms: [],
      temperature: 0,
      temperatureUnit: "F",
      patientAge: 0,
      patientAgeUnit: "year",
      chronicConditionFlag: "",
      chinaVisitFlag: "",
      immunizationFlag: "",
      followupCheckFlag: "",
      patientWeight: 0,
      patientWeightUnit: "lb",
      email: "",
      zipcode: "",
      state_treatingSymptoms: "",
      state_takingMedicationFlag: "",
      state_medications_list: "",
      state_locations_visited: "",
      state_length_of_stay: "",
      state_purpose_of_visit: "",
      state_contated_number_of_people: "",
      state_comparison_to_before: "",
      state_awareVirusOutbreakFlag: "",
      state_diagnosednCovFlag: "",
      state_knowSomeOneDiagnosedFlag: "",
      state_travlledInPastTwoWeeksFlag: ""
    };
  }
  moveNext = () => {
    window.scrollTo(0, 0);
    if (this.props.feverRecommendationNavigationIndex == 1) {
      if (this.state.temperature == 0) {
        this.setState({ temperature: this.props.symptomDetails.temperature });
        this.setState({
          temperatureUnit: this.props.symptomDetails.temperatureUnit
        });
      }
      if (
        this.state.temperature == null ||
        (this.state.temperature == 0 &&
          (this.props.symptomDetails.temperature == null ||
            this.props.symptomDetails.temperature == 0))
      ) {
        this.props.guidanceActions.triggerTemperatureRequiredError(true);
        return;
      }
      this.props.guidanceActions.storeSymptoms(
        this.state.temperature,
        this.state.temperatureUnit,
        this.state.selectedSymptoms
      );
    } else if (this.props.feverRecommendationNavigationIndex == 2) {
      if (this.state.patientAge == null || this.state.patientAge == 0) {
        this.props.guidanceActions.triggerPatientAgeRequiredError(true);
        return;
      }
      this.props.guidanceActions.storePatientDetails(
        this.state.patientAge,
        this.state.patientAgeUnit,
        this.state.chronicConditionFlag,
        this.state.immunizationFlag
      );
    } else if (this.props.feverRecommendationNavigationIndex == 3) {
      this.props.guidanceActions.storeAdditionalDetails(this.state.patientWeight, this.state.patientWeightUnit, this.state.email, this.state.zipcode
        , this.state.followupCheckFlag, this.state.chinaVisitFlag, this.state_treatingSymptoms,
        this.state_takingMedicationFlag,
        this.state_medications_list,
        this.state_locations_visited,
        this.state_length_of_stay,
        this.state_purpose_of_visit,
        this.state_contated_number_of_people,
        this.state_comparison_to_before,
        this.state_awareVirusOutbreakFlag,
        this.state_diagnosednCovFlag,
        this.state_knowSomeOneDiagnosedFlag,
        this.state_travlledInPastTwoWeeksFlag)
      this.lookupFeverRecommendations();
    }
    this.props.guidanceActions.feverRecommendationsMoveNext();
  };

  moveBack = () => {
    window.scrollTo(0, 0);
    this.props.guidanceActions.feverRecommendationsMoveBack();
  };

  // componentWillUpdate = () => {
  // if(this.props.feverRecommendationNavigationIndex == 1){
  //   this.props.guidanceActions.resetFeverRecommendations()
  //   this.setState({
  //     selectedSymptoms: [],
  //     temperature: 0,
  //     temperatureUnit: '',
  //     patientAge: 0,
  //     patientAgeUnit: 'year',
  //     chronicConditionFlag: '',
  //     immunizationFlag: '',
  //     followupCheckFlag: '',
  //     patientWeight: 0,
  //     patientWeightUnit: 'lb',
  //     email: '',
  //     zipcode: ''
  //   })
  // }
  // }

  componentDidMount() {
    // console.log('componentDidMount')
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.feverRecommendationNavigationIndex == 1) {
      this.setState({
        selectedSymptoms: [],
        patientAge: 0,
        patientAgeUnit: "year",
        chronicConditionFlag: "",
        chinaVisitFlag: "",
        immunizationFlag: "",
        followupCheckFlag: "",
        patientWeight: 0,
        patientWeightUnit: "lb",
        email: "",
        zipcode: "",
        state_treatingSymptoms: "",
      });
      this.setState({ selectedSymptoms: [] });
    }
  }

  componentWillUpdate(nextProps) {
    // console.log('componentWillUpdate')
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate')
  }

  setTemperature = e => {
    this.setState({ temperature: e.target.value });
  };
  setTemperatureUnit = e => {
    this.setState({ temperatureUnit: e.target.value });
  };
  selectSymptom = symptom => {
    this.setState({
      selectedSymptoms: [symptom].concat(this.state.selectedSymptoms)
    });
    // console.log(this.state.selectedSymptoms)
  };
  unselectSymptom = symptom => {
    this.setState({
      selectedSymptoms: this.state.selectedSymptoms.filter(
        sym => sym !== symptom
      )
    });
    // console.log(this.state.selectedSymptoms)
  };
  setPatientAge = e => {
    this.setState({ patientAge: e.target.value });
  };
  setPatientAgeUnit = e => {
    this.setState({ patientAgeUnit: e.target.value });
  };
  setChronicConditionFlag = val => {
    this.setState({ chronicConditionFlag: val });
  };

  setImmunizationFlag = val => {
    this.setState({ immunizationFlag: val });
  };
  setFollowupCheckFlag = val => {
    this.setState({ followupCheckFlag: val });
  };
  setPatientWeight = e => {
    this.setState({ patientWeight: e.target.value });
  };
  setPatientWeightUnit = e => {
    this.setState({ patientWeightUnit: e.target.value });
  };
  setEmail = e => {
    this.setState({ email: e.target.value });
  };
  setZipcode = e => {
    this.setState({ zipcode: e.target.value });
  };

  // NEW Additions after CORONA VIRUS outbreak

  setChinaVisitFlag = val => {
    this.setState({ chinaVisitFlag: val });
  };

  setTreatingSymptoms = e => {
    this.setState({ state_treatingSymptoms: e.target.value })
  }

  setTakingMedication = e => {
    this.setState({ state_takingMedicationFlag: e })
  }

  setMedicationsList = e => {
    console.log("Meds LIst -> ", e.target.value)
    this.setState({ state_medications_list: e.target.value })
  }

  setLocationsList = e => {
    this.setState({ state_locations_visited: e.target.value })
  }

  setLengthOfStay = e => {
    console.log("Length of stay --> ", e.target)
    this.setState({ state_length_of_stay: e.target.value })
  }

  setPurposeOfVisit = e => {
    this.setState({ state_purpose_of_visit: e.target.value })
  }

  setTravelledInPastTwoWeeks = e => {
    this.setState({ state_travlledInPastTwoWeeksFlag: e })
  }

  setContactInPastDay = e => {
    this.setState({ state_contated_number_of_people: e.target.value })
  }

  setComparePercentage = e => {
    this.setState({ state_comparison_to_before: e.target.value })
  }

  setAwareVirusOutbreak = e => {
    this.setState({ state_awareVirusOutbreakFlag: e })
  }

  setDiagnosednCov = e => {
    this.setState({ state_diagnosednCovFlag: e })
  }

  setKnowSomeoneDianosed = e => {
    this.setState({ state_knowSomeOneDiagnosedFlag: e })
  }

  lookupFeverRecommendations = () => {
    var symptomDetails = {};
    symptomDetails.temperature = this.state.temperature;
    symptomDetails.temperatureUnit = this.state.temperatureUnit;
    symptomDetails.patientAge = this.state.patientAge;
    symptomDetails.patientAgeUnit = this.state.patientAgeUnit;
    symptomDetails.chronicConditionFlag = this.state.chronicConditionFlag;
    symptomDetails.chinaVisitFlag = this.state.chinaVisitFlag;
    symptomDetails.immunizationFlag = this.state.immunizationFlag;
    symptomDetails.followupCheckFlag = this.state.followupCheckFlag;
    symptomDetails.patientWeight = this.state.patientWeight;
    symptomDetails.patientWeightUnit = this.state.patientWeightUnit;
    symptomDetails.email = this.state.email;
    symptomDetails.zipcode = this.state.zipcode;
    symptomDetails.symptoms = this.state.selectedSymptoms;

    symptomDetails.treatingSymptoms = this.state.state_treatingSymptoms;
    symptomDetails.takingMedicationFlag = this.state.state_takingMedicationFlag;
    symptomDetails.medications_list = this.state.state_medications_list;
    symptomDetails.locations_visited = this.state.state_locations_visited;
    symptomDetails.length_of_stay = this.state.state_length_of_stay;
    symptomDetails.purpose_of_visit = this.state.state_purpose_of_visit;
    symptomDetails.contated_number_of_people = this.state.state_contated_number_of_people;
    symptomDetails.comparison_to_before = this.state.state_comparison_to_before;
    symptomDetails.awareVirusOutbreakFlag = this.state.state_awareVirusOutbreakFlag;
    symptomDetails.diagnosednCovFlag = this.state.state_diagnosednCovFlag;
    symptomDetails.knowSomeOneDiagnosedFlag = this.state.state_knowSomeOneDiagnosedFlag;
    symptomDetails.travelledInPastTwoWeeksFlag = this.state.state_travlledInPastTwoWeeksFlag;
    symptomDetails.referral = this.props.referral;

    var sessionId = SessionTracker.getSessionID();
    console.log("This symptomDetails before submission  -> ", this.state)
    this.props.guidanceActions.getFeverGuidance(symptomDetails, sessionId);
  };

  render() {
    return (
      <div className="secondary">
        <Navbar />
        <div className="secondary-content">
          <div className="container">
            <div className="row">
              <div className="col text-center">
                <div className="form-wrapper mt-4">
                  <ProgressTabs
                    setTemperature={this.setTemperature}
                    setTemperatureUnit={this.setTemperatureUnit}
                    selectSymptom={this.selectSymptom}
                    unselectSymptom={this.unselectSymptom}
                    setPatientAge={this.setPatientAge}
                    setPatientAgeUnit={this.setPatientAgeUnit}
                    setChronicConditionFlag={this.setChronicConditionFlag}
                    setChinaVisitFlag={this.setChinaVisitFlag}
                    setImmunizationFlag={this.setImmunizationFlag}
                    setFollowupCheckFlag={this.setFollowupCheckFlag}
                    setPatientWeight={this.setPatientWeight}
                    setPatientWeightUnit={this.setPatientWeightUnit}
                    setEmail={this.setEmail}
                    setZipcode={this.setZipcode}
                    setTreatingSymptoms={this.setTreatingSymptoms}
                    setTakingMedication={this.setTakingMedication}
                    setMedicationsList={this.setMedicationsList}
                    setLocationsList={this.setLocationsList}
                    setLengthOfStay={this.setLengthOfStay}
                    setPurposeOfVisit={this.setPurposeOfVisit}
                    setContactInPastDay={this.setContactInPastDay}
                    setComparePercentage={this.setComparePercentage}
                    setAwareVirusOutbreak={this.setAwareVirusOutbreak}
                    setDiagnosednCov={this.setDiagnosednCov}
                    setKnowSomeoneDianosed={this.setKnowSomeoneDianosed}
                    setTravelledInPastTwoWeeks={this.setTravelledInPastTwoWeeks}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-3 mb-5 pb-5">
              <div className="col text-center d-flex">
                {this.props.feverRecommendationNavigationIndex &&
                  this.props.feverRecommendationNavigationIndex > 1 && (
                    <Button
                      rounded
                      outline
                      color="blue"
                      className="mr-auto btn-sm-width"
                      onClick={this.moveBack}
                    >
                      <FormattedMessage
                        id="CheckFever.BackButton"
                        defaultMessage="Back"
                      />
                    </Button>
                  )}
                {this.props.feverRecommendationNavigationIndex &&
                  this.props.feverRecommendationNavigationIndex < 4 && (
                    <Button
                      rounded
                      color="primary blue"
                      className="ml-auto btn-sm-width"
                      onClick={this.moveNext}
                    >
                      <FormattedMessage
                        id="CheckFever.NextButton"
                        defaultMessage="Next"
                      />
                    </Button>
                  )}
                {this.props.feverRecommendationNavigationIndex &&
                  this.props.feverRecommendationNavigationIndex == 4 && (
                    <a
                      className="btn btn-primary btn-rounded ml-auto btn-sm-width"
                      href="/checkfever"
                    >
                      <FormattedMessage
                        id="CheckFever.ResetButton"
                        defaultMessage="Start over"
                      />
                    </a>
                  )}
                {/* 
                <Button rounded color="primary" className="mr-3">
                  Email Results
                </Button>
                <Button rounded color="primary blue" className="">
                  Save to desktop
                </Button>
			*/}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

// export default CheckFever;

function mapStateToProps(state) {
  return {
    feverRecommendationNavigationIndex:
      state.feverRecommendationsState.feverRecommendationNavigationIndex,
    symptomDetails: state.feverRecommendationsState.symptomDetails,
    referral: state.feverRecommendationsState.referral
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckFever);
