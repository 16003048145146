import React from 'react';
import ReactDOM from 'react-dom';
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { IntlProvider, addLocaleData } from 'react-intl';

import 'font-awesome/css/font-awesome.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
//import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './configureStore'

import en from 'react-intl/locale-data/en';
import es from 'react-intl/locale-data/es';

import messages_en from "./translations/en.json";
import messages_es from "./translations/es.json";

addLocaleData([...en, ...es]);
const messages = {
    'en': messages_en,
    'es': messages_es
};
// const {locale, messages} = window.App;
const locale = "en"

const store = configureStore();

// ReactDOM.render(<App />, document.getElementById('root'));

const Routing = () => (
  <Provider store={store}>
    <IntlProvider locale={locale} messages={messages[locale]}>
    <App />
    </IntlProvider>
  </Provider>
)

render(<Routing />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
