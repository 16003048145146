import React, { Component } from "react";
import headache from "../images/symptoms/headache.svg";
import headachewhite from "../images/symptoms/headache-white.svg";
import { FormattedMessage } from "react-intl";

class SymptomHeadache extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={headache} className="symptom-icon" alt="Headache" />
        <img src={headachewhite} className="white-outline symptom-icon" alt="Headache" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomHeadache.Title"
            defaultMessage="Headache"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomHeadache;
