import React, { Component } from "react";
import sorethroat from "../images/symptoms/sore-throat.svg";
import sorethroatwhite from "../images/symptoms/sore-throat-white.svg";
import { FormattedMessage } from "react-intl";
class SymptomSoreThroat extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={sorethroat} className="symptom-icon" alt="Sore throat" />
        <img src={sorethroatwhite} className="symptom-icon white-outline" alt="Sore throat" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomSoreThroat.Title"
            defaultMessage="Sore throat"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomSoreThroat;
