import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ModalCough extends Component {
  render() {
    return (
      <div className="symptoms-details">
        <p>
          <FormattedMessage
            id="ModalCough.P1"
            defaultMessage="Coughing is a common reflex that helps clear the body's airways. The most common causes of cough in children are infections, asthma, or other lung conditions. Coughs caused by a viral infection will generally resolve on their own."
          />
        </p>
        <p>
          <FormattedMessage
            id="ModalCough.P2"
            defaultMessage="If your child displays any of the following, contact your pediatrician or primary care provider."
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalCough.List1Item1"
              defaultMessage="Coughing so hard he or she vomits"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalCough.List1Item2"
              defaultMessage="Coughing up blood or mucus"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalCough.List1Item3"
              defaultMessage="Child is under 3 months old"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalCough.List1Item4"
              defaultMessage="Persistent cough for over 2 weeks"
            />
          </li>
        </ul>
        <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
        <p>
          <FormattedMessage
            id="ModalCough.P3"
            defaultMessage="To ease cough symptoms, you can try the following:"
          />
        </p>
        <ul>
          <li>
            <FormattedMessage
              id="ModalCough.List2Item1"
              defaultMessage="Give your child plenty of fluids"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalCough.List2Item2"
              defaultMessage="Use a humidifier in his or her room"
            />
          </li>
          <li>
            <FormattedMessage
              id="ModalCough.List2Item3"
              defaultMessage="Sit in the bathroom while you run hot water to create steam"
            />
          </li>
        </ul>
        <p>
          <FormattedMessage
            id="ModalCough.P4"
            defaultMessage="Do not give over-the-counter cough and cold medicine to children. These medicines are not likely to help and can cause serious problems in young children."
          />
        </p>
        <p>
          <FormattedMessage
            id="Modals.TreatmentOptions.MoreInfo"
            defaultMessage="For more information, see {link}"
            values={{
              link: (
                <a
                  className="link"
                  href="http://www.childrenshospital.org/conditions-and-treatments/conditions/cough"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FormattedMessage
                    id="Modals.TreatmentOptions.MoreInfo.Link"
                    defaultMessage="Boston Children's Hospital Treatment Options"
                  />
                </a>
              )
            }}
          />
        </p>
      </div>
    );
  }
}

export default ModalCough;
