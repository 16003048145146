import React, { Component } from "react";
import rash from "../images/symptoms/rash.svg";
import rashwhite from "../images/symptoms/rash-white.svg";
import { FormattedMessage } from "react-intl";
class SymptomRash extends Component {
  render() {
    return (
      <div className="symptom-group d-flex flex-column justify-content-center">
        <img src={rash} className="symptom-icon" alt="Rash" />
        <img src={rashwhite} className="white-outline symptom-icon" alt="Rash" />
        <h5 className="mt-3 mb-0">
		<FormattedMessage
            id="SymptomRash.Title"
            defaultMessage="Rash"
          />
		</h5>
      </div>
    );
  }
}

export default SymptomRash;
