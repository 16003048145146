import * as ActionTypes from '../actions/action-types';
import * as KidsMDApi from '../api/kidsmd-api'

export function getFeverGuidance(symptomDetails, sessionId) {
  return function (dispatch) {
    return KidsMDApi.getFeverRecommendations(symptomDetails, sessionId).then(recommendations => {
      if (recommendations && recommendations[0] && recommendations[0].description) {
        // recommendations[0].description = recommendations[0].description.replace(/<br\/>/g, '')
      }

      dispatch({ type: ActionTypes.GET_FEVER_RECOMMENDATIONS_SUCCESS, recommendations });
    }).catch(error => {
      throw (error);
    })
  }
}

export function captureNewUserSession(sessionId, referral) {
  return function (dispatch) {
    return KidsMDApi.captureNewUserSession(sessionId, referral).then(response => {
      dispatch({ type: ActionTypes.NEW_USER_SESSION_SUCCESS, response, referral });
    }).catch(error => {
      throw (error);
    })
  }
}

export function storeTemperature(temperature, temperatureUnit) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_TEMPERATURE, temperature, temperatureUnit });
  }
}

export function storeSymptoms(temperature, temperatureUnit, symptoms) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_SYMPTOMS, temperature, temperatureUnit, symptoms });
  }
}

export function storePatientDetails(patientAge, patientAgeUnit, chronicConditionFlag, immunizationFlag) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.STORE_PATIENT_DETAILS, patientAge, patientAgeUnit, chronicConditionFlag, immunizationFlag });
  }
}

export function storeAdditionalDetails(patientWeight, patientWeightUnit, email, zipcode, followupCheckFlag, chinaVisitFlag, treatingSymptoms,
  takingMedicationFlag,
  medications_list,
  locations_visited,
  length_of_stay,
  purpose_of_visit,
  contated_number_of_people,
  comparison_to_before,
  awareVirusOutbreakFlag,
  diagnosednCovFlag,
  knowSomeOneDiagnosedFlag,
  travelledInPastTwoWeeksFlag) {
  return function (dispatch) {
    dispatch({
      type: ActionTypes.STORE_ADDITIONAL_DETAILS, patientWeight, patientWeightUnit, email, zipcode, followupCheckFlag, chinaVisitFlag, treatingSymptoms,
      takingMedicationFlag,
      medications_list,
      locations_visited,
      length_of_stay,
      purpose_of_visit,
      contated_number_of_people,
      comparison_to_before,
      awareVirusOutbreakFlag,
      diagnosednCovFlag,
      knowSomeOneDiagnosedFlag,
      travelledInPastTwoWeeksFlag
    });
  }
}

export function resetFeverRecommendations(referral) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.RESET_FEVER_RECOMMENDATIONS, referral });
  }
}

export function feverRecommendationsMoveNext() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.FEVER_RECOMMENDATION_MOVE_NEXT });
  }
}

export function feverRecommendationsMoveBack() {
  return function (dispatch) {
    dispatch({ type: ActionTypes.FEVER_RECOMMENDATION_MOVE_BACK });
  }
}

export function triggerTemperatureRequiredError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.FEVER_TEMPERATURE_REQUIRED_ERROR, status });
  }
}

export function triggerPatientAgeRequiredError(status) {
  return function (dispatch) {
    dispatch({ type: ActionTypes.FEVER_PATIENT_AGE_REQUIRED_ERROR, status });
  }
}

