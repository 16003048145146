import * as types from '../actions/action-types';
import initialState from './initialState';

const FeverRecommendationsReducer = function (state = initialState, action) {

  switch (action.type) {
    case types.STORE_TEMPERATURE:
      var stateObj = Object.assign({}, state, {
        symptomDetails: {
          ...state.symptomDetails,
          temperature: action.temperature,
          temperatureUnit: action.temperatureUnit
        }
      });
      // console.log(JSON.stringify(stateObj));
      return stateObj;
    case types.STORE_SYMPTOMS:
      var stateObj = Object.assign({}, state, {
        symptomDetails: {
          ...state.symptomDetails,
          temperature: action.temperature,
          temperatureUnit: action.temperatureUnit,
          symptoms: action.symptoms,
          temperatureRequiredError: false
        }
      });
      // console.log(JSON.stringify(stateObj));
      return stateObj;
    case types.STORE_PATIENT_DETAILS:
      var stateObj = Object.assign({}, state, {
        symptomDetails: {
          ...state.symptomDetails,
          patientAge: action.patientAge,
          patientAgeUnit: action.patientAgeUnit,
          chronicConditionFlag: action.chronicConditionFlag,
          immunizationFlag: action.immunizationFlag
        }
      });
      // console.log(JSON.stringify(stateObj));
      return stateObj;
    case types.STORE_ADDITIONAL_DETAILS:
      var stateObj = Object.assign({}, state, {
        symptomDetails: {
          ...state.symptomDetails,
          patientWeight: action.patientWeight,
          patientWeightUnit: action.patientWeightUnit,
          email: action.email,
          zipcode: action.zipcode,
          followupCheckFlag: action.followupCheckFlag,
          chinaVisitFlag: action.chinaVisitFlag,
        }
      });
      // console.log(JSON.stringify(stateObj));
      return stateObj;
    case types.FEVER_RECOMMENDATION_MOVE_NEXT:
      return Object.assign({}, state, { feverRecommendationNavigationIndex: state.feverRecommendationNavigationIndex + 1 });
    case types.FEVER_RECOMMENDATION_MOVE_BACK:
      return Object.assign({}, state, { feverRecommendationNavigationIndex: state.feverRecommendationNavigationIndex - 1 });
    case types.GET_FEVER_RECOMMENDATIONS_SUCCESS:
      return Object.assign({}, state, { feverRecommendations: action.recommendations });
    case types.RESET_FEVER_RECOMMENDATIONS:
      return Object.assign({}, state, { feverRecommendations: {}, symptomDetails: {}, feverRecommendationNavigationIndex: 1, referral: action.referral });
    case types.FEVER_TEMPERATURE_REQUIRED_ERROR:
      return Object.assign({}, state, { temperatureRequiredError: action.status });
    case types.FEVER_PATIENT_AGE_REQUIRED_ERROR:
      return Object.assign({}, state, { patientAgeRequiredError: action.status });
    case types.NEW_USER_SESSION_SUCCESS:
      return Object.assign({}, state, { referral: action.referral });
  }

  return state;
}
export default FeverRecommendationsReducer
