import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormattedMessage } from "react-intl";
import {
  Container,
  Card,
  CardBody,
  CardText,
  Modal,
  ModalBody,
  ModalHeader,
  Fa
} from "mdbreact";
import resultsdr from "../images/results-dr.svg";
import resultshometreatment from "../images/results-home-treatment.svg";
import Doses from "./Doses";
import DoseCalculator from "./DoseCalculator";
import SymptomRash from "./SymptomRash";
import SymptomSoreThroat from "./SymptomSoreThroat";
import SymptomCough from "./SymptomCough";
import SymptomShortBreath from "./SymptomShortBreath";
import SymptomHeadache from "./SymptomHeadache";
import SymptomVomiting from "./SymptomVomiting";
import SymptomDiarrhea from "./SymptomDiarrhea";
import SymptomFatigue from "./SymptomFatigue";
import ModalRash from "./ModalRash";
import ModalSoreThroat from "./ModalSoreThroat";
import ModalCough from "./ModalCough";
import ModalShortBreath from "./ModalShortBreath";
import ModalHeadache from "./ModalHeadache";
import ModalVomiting from "./ModalVomiting";
import ModalDiarrhea from "./ModalDiarrhea";
import ModalFatigue from "./ModalFatigue";
import * as GuidanceActions from "../actions/guidance-actions";
import ReactGA from 'react-ga';

class Results extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }
  toggle(nr) {
    let modalNumber = "modal" + nr;
    this.setState({
      [modalNumber]: !this.state[modalNumber]
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps");

    if (nextProps.feverRecommendations
      && nextProps.feverRecommendations[0]) {
      if (nextProps.feverRecommendations[0].high_fever
        && nextProps.referral == "first") {
        this.pushEventForUsageAnalytics("ConsultWithDoctor");
      }
      ReactGA.event({
        category: 'ThermiaRecommendation',
        action: 'HighFever',
        label: "" + nextProps.feverRecommendations[0].high_fever
      });
    }
  }

  pushEventForUsageAnalytics(eventLabel) {
    window.uetq = window.uetq || [];
    window.uetq.push({ 'ec': 'ThermiaRecommendation', 'ea': 'Result', 'el': eventLabel });
    console.log('added event for Bing tracking')
  }

  render() {
    console.log('Props in result page: ', this.props.symptomDetails)
    console.log("Recommendations From Services -> ", this.props.feverRecommendations)
    return (
      <Container>
        <Modal
          isOpen={this.state.modal1}
          toggle={() => this.toggle(1)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(1)}>
            <FormattedMessage
              id="Results.ModalTitle.SoreThroat"
              defaultMessage="Sore throat"
            />
          </ModalHeader>
          <ModalBody>
            <ModalSoreThroat />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal2}
          toggle={() => this.toggle(2)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(2)}>
            <FormattedMessage
              id="Results.ModalTitle.Rash"
              defaultMessage="Rash"
            />
          </ModalHeader>
          <ModalBody>
            <ModalRash />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal3}
          toggle={() => this.toggle(3)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(3)}>
            <FormattedMessage
              id="Results.ModalTitle.Cough"
              defaultMessage="Cough"
            />
          </ModalHeader>
          <ModalBody>
            <ModalCough />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal4}
          toggle={() => this.toggle(4)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(4)}>
            <FormattedMessage
              id="Results.ModalTitle.Diarrhea"
              defaultMessage="Diarrhea"
            />
          </ModalHeader>
          <ModalBody>
            <ModalDiarrhea />
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modal5}
          toggle={() => this.toggle(5)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(5)}>
            <FormattedMessage
              id="Results.ModalTitle.Fatigue"
              defaultMessage="Fatigue"
            />
          </ModalHeader>
          <ModalBody>
            <ModalFatigue />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal6}
          toggle={() => this.toggle(6)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(6)}>
            <FormattedMessage
              id="Results.ModalTitle.Headache"
              defaultMessage="Headache"
            />
          </ModalHeader>
          <ModalBody>
            <ModalHeadache />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal7}
          toggle={() => this.toggle(7)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(7)}>
            <FormattedMessage
              id="Results.ModalTitle.ShortBreath"
              defaultMessage="Shortness of breath"
            />
          </ModalHeader>
          <ModalBody>
            <ModalShortBreath />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.modal8}
          toggle={() => this.toggle(8)}
          fullHeight
          className="med-width symptoms"
          position="right"
        >
          <ModalHeader toggle={() => this.toggle(8)}>
            <FormattedMessage
              id="Results.ModalTitle.Vomiting"
              defaultMessage="Vomiting"
            />
          </ModalHeader>
          <ModalBody>
            <ModalVomiting />
          </ModalBody>
        </Modal>
        <div className="text-left">
          <p className="pb-3 mb-3">
            <FormattedMessage
              id="Results.Disclaimer"
              defaultMessage="This information is educational and intended to assist you in learning more about fever and illness. This is not medical advice. Call your doctor for medical advice or call 911 if you need immediate assistance."
            />
          </p>
          <h2 className="underline light mb-4">
            <FormattedMessage
              id="Results.Section1.Title"
              defaultMessage="Next steps"
            />
          </h2>
          {/* Has Fever. But, did not visit China in last 2 weeks (01/29/2020) */}
          {this.props.feverRecommendations &&
            this.props.feverRecommendations[0] &&
            (this.props.feverRecommendations[0].high_fever) &&
            (!(this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag === "yes")) && (
              <span>
                <Card className="col-lg-8 col-12 p-0">
                  <CardBody>
                    <div className="d-inline-flex">
                      <div className="align-self-center hidden-xs-down">
                        <img className="mr-3" alt="Consult Dr." src={resultsdr} />
                      </div>
                      <div className="align-self-center">
                        <h4 className="secondary mb-3">
                          <FormattedMessage
                            id="Results.Section1.Card1.Title"
                            defaultMessage="Consult with your doctor"
                          />
                        </h4>

                        {this.props.feverRecommendations &&
                          this.props.feverRecommendations[0] && (
                            <CardText className="mb-0">
                              {this.props.feverRecommendations[0].description}
                              {/* <br/><br/>
                     {this.props.feverRecommendations[0].description_additional}
		             */}{" "}
                            </CardText>
                          )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </span>
            )}
          {/* No High Fever (There could be mild fever, but wasn't accounted for at this block) and did not visit China in past two weeks (01/29/2020) */}
          {this.props.feverRecommendations &&
            this.props.feverRecommendations[0] &&
            (!this.props.feverRecommendations[0].high_fever) &&
            !(this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag === "yes") && (
              <span>
                <Card className="col-lg-8 col-12 p-0">
                  <CardBody>
                    <div className="d-inline-flex">
                      <div className="align-self-center hidden-xs-down">
                        <img
                          className="mr-3"
                          alt="Treat at home"
                          src={resultshometreatment}
                        />
                      </div>
                      <div className="align-self-center">
                        <h4 className="secondary mb-3">
                          <FormattedMessage
                            id="Results.Section1.Card2.Title"
                            defaultMessage="Treat at home and call your doctor if you are still concerned."
                          />
                        </h4>
                        {this.props.feverRecommendations &&
                          this.props.feverRecommendations[0] && (
                            <CardText className="mb-0">
                              {this.props.feverRecommendations[0].description}
                              {/*  <br/><br/>
                     {this.props.feverRecommendations[0].description_additional}
		             */}{" "}
                            </CardText>
                          )}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </span>
            )}
          {/* visited China and has fever (Mild or HIGH doesn't matter) */}
          {this.props.feverRecommendations &&
            this.props.feverRecommendations[0] &&
            (this.props.feverRecommendations[0].typeOfFever !== "no_fever") &&
            (this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag === "yes") && (
              <span>
                <Card className="col-lg-8 col-12 p-0">
                  <CardBody>
                    <div className="d-inline-flex">
                      <div className="align-self-center hidden-xs-down">
                        <img className="mr-3" alt="Consult Dr." src={resultsdr} />
                      </div>
                      <div className="align-self-center">
                        <h4 className="secondary mb-3">
                          <FormattedMessage
                            id="Results.Section1.Card3.Title"
                            defaultMessage="Seek medical care right away"
                          />
                        </h4>

                        <CardText className="mb-0">
                          Before you go to a doctor’s office or emergency room, call ahead and tell
                        them about your symptoms and recent travel to China in the past 14 days.
                      </CardText>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </span>
            )}
          {/* visited China and has no fever */}
          {this.props.feverRecommendations &&
            this.props.feverRecommendations[0] &&
            (this.props.feverRecommendations[0].typeOfFever == "no_fever") &&
            (this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag === "yes") && (
              <span>
                <Card className="col-lg-8 col-12 p-0">
                  <CardBody>
                    <div className="d-inline-flex">
                      <div className="align-self-center hidden-xs-down">
                        <img
                          className="mr-3"
                          alt="Treat at home"
                          src={resultshometreatment}
                        />
                      </div>
                      <div className="align-self-center">
                        <h4 className="secondary mb-3">
                          <FormattedMessage
                            id="Results.Section1.Card4.Title"
                            defaultMessage="Monitor your symptoms and get medical care if new symptoms arise or your illness is getting worse."
                          />
                        </h4>
                        <CardText className="mb-0">
                          <br />Before you go to a doctor’s office or emergency room, call ahead and tell them about your symptoms and recent travel to China in the past 14 days.
                        </CardText>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </span>
            )}

            {this.props.feverRecommendations &&
              this.props.feverRecommendations[0] &&
              (this.props.feverRecommendations[0].typeOfFever !== "no_fever") &&
              (this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag === "yes") && (
                <span>
                  <hr />
                  <h2 className="underline light mb-4">
                    <FormattedMessage
                      id="Results.Section16.Title"
                      defaultMessage="Stay home except to get medical care"
                    />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Results.Section16.P1"
                      defaultMessage="You should not leave your home, except to get medical care. Do not go to work, school, or public areas, and do not use public transportation or taxis. Separate yourself from other people in your home As much as possible, you should stay in a different room from other people in your home. Also, you should use a separate bathroom, if available." />
                  </p>

                  <h2 className="underline light mb-4">
                    <FormattedMessage
                      id="Results.Section17.Title"
                      defaultMessage="Call ahead before visiting your doctor"
                    />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Results.Section17.P1" defaultMessage="Before your medical appointment, call the healthcare provider and tell them that you have these symptoms and have been to China in the past 14 days.  This will help the healthcare provider’s office take steps to keep other people from getting infected." />
                  </p>

                  <h2 className="underline light mb-4">
                    <FormattedMessage
                      id="Results.Section18.Title"
                      defaultMessage="Wear a face mask"
                    />
                  </h2>
                  <p>
                    <FormattedMessage
                      id="Results.Section18.P1" defaultMessage="You should wear a facemask when you are in the same room with other people and when you visit a healthcare provider. If you cannot wear a facemask, the people who live with you should wear one while they are in the same room with you." />
                  </p>
                </span>
              )}
            {(this.props.symptomDetails && this.props.symptomDetails.chinaVisitFlag) && (
              <span>
                <hr />
                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section9.Title"
                    defaultMessage="Cover your coughs and sneezes"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section9.P1"
                    defaultMessage="Cover your mouth and nose with a tissue when you cough or sneeze, or you can cough or sneeze into your sleeve. Throw used tissues in a lined trash can, and immediately wash your hands with soap and water for at least 20 seconds."
                  />
                </p>

                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section10.Title"
                    defaultMessage="Wash your hands"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section10.P1"
                    defaultMessage="Wash your hands often and thoroughly with soap and water for at least 20 seconds. You can use an alcohol-based hand sanitizer if soap and water are not available and if your hands are not visibly dirty. Avoid touching your eyes, nose, and mouth with unwashed hands."
                  />
                </p>

                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section11.Title"
                    defaultMessage="Avoid sharing household items"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section11.P1"
                    defaultMessage="You should not share dishes, drinking glasses, cups, eating utensils, towels, bedding, or other items with other people in your home. After using these items, you should wash them thoroughly with soap and water."
                  />
                </p>

                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section12.Title"
                    defaultMessage="Monitor your symptoms"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section12.P1"
                    defaultMessage="Get medical care quickly if your illness is getting worse (for example if you are having trouble breathing). Call the healthcare provider ahead of time and tell them that you have these symptoms and have been to China in the past 14 days. This will help the healthcare provider’s office take steps to keep other people from getting infected."
                  />
                </p>

                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section13.Title"
                    defaultMessage="Avoid contact with pets and animals"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section13.P1"
                    defaultMessage="Do not handle pets or other animals while sick. Avoid contact with animals and wear a facemask if you must be around animals or care for a pet."
                  />
                </p>
              </span>
            )}

          {!(this.props.symptomDetails &&
            this.props.symptomDetails.chinaVisitFlag === "yes") && (
              <span>
                <hr />
                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section2.Title"
                    defaultMessage="About fever"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section2.P1"
                    defaultMessage="A fever is an increased in your child's body temperature. Most fevers are caused by an infection. For example, fever is a common symptom of infection with the influenza virus (commonly called the flu)."
                  />
                </p>
                <p>
                  <FormattedMessage
                    id="Results.Section2.P2"
                    defaultMessage="If your child displays any of the following symptoms, it may be a sign of a more serious infection or other illness. Please contact your doctor or healthcare provider."
                  />
                </p>
                <ul>
                  <li>
                    <FormattedMessage
                      id="Results.Section2.List1.Item1"
                      defaultMessage="Seizure"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section2.List1.Item2"
                      defaultMessage="Irritability orinconsolability"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section2.List1.Item3"
                      defaultMessage="Recurring fever (fever goes away and comes back)"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section2.List1.Item4"
                      defaultMessage="Refusal of liquids"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section2.List1.Item5"
                      defaultMessage="Other signs of dehydration (no urine in 6-8 hours or fewer than 5 wet diapers per day)"
                    />
                  </li>
                </ul>

                <hr />
                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section3.Title"
                    defaultMessage="At home treatment"
                  />
                </h2>
                <p>
                  <FormattedMessage
                    id="Results.Section3.P1"
                    defaultMessage="If the patient is uncomfortable, you can treat at home with the following suggestions:"
                  />
                </p>
                <ul className="mb-4">
                  <li>
                    <FormattedMessage
                      id="Results.Section3.List1.Item1"
                      defaultMessage="Offer plenty of fluids"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section3.List1.Item1"
                      defaultMessage="Encourage the patient to rest as much as he or she wants"
                    />
                  </li>
                  <li>
                    <FormattedMessage
                      id="Results.Section3.List1.Item3"
                      defaultMessage="Medicines like acetaminophen (Tylenol) or ibuprofen (Advil, Motrin) can help bring down a fever. Do not use aspirin in children under 18 years."
                    />
                  </li>
                </ul>

                {this.props.feverRecommendations &&
                  this.props.feverRecommendations[0] && (
                    <p className="mb-0">
                      <FormattedMessage
                        id="Modals.TreatmentOptions.MoreInfo"
                        defaultMessage="For more information, see {link}"
                        values={{
                          link: (
                            <a
                              className="link"
                              href="{this.props.feverRecommendations[0].url[0]}"
                              target="_blank"
                            >
                              <FormattedMessage
                                id="Modals.TreatmentOptions.MoreInfo.Link"
                                defaultMessage="Boston Children's Hospital Treatment Options "
                              />
                              <Fa className="ml-1" icon="arrow-right" />
                            </a>
                          )
                        }}
                      />
                    </p>
                  )}
              </span>
            )}

          <hr />
          <h2 className="underline light mb-4">
            <FormattedMessage
              id="Results.Section4.Title"
              defaultMessage="Dosing recommendations"
            />
          </h2>
          <p className="mb-4">
            <FormattedMessage
              id="Results.Section4.P1"
              defaultMessage="The dosing guidelines below are used by Boston Children’s Hospital."
            />
          </p>
          <span>
            {this.props.feverRecommendations &&
              this.props.feverRecommendations[1] &&
              this.props.feverRecommendations[1].calc_info && (
                <Doses
                  dosingRecommendations={this.props.feverRecommendations[1]}
                />
              )}
          </span>
          <p className="">
            {(!this.props.feverRecommendations ||
              !this.props.feverRecommendations[1] ||
              !this.props.feverRecommendations[1].weight_based) && (
                <span>
                  <FormattedMessage
                    id="Results.Section4.P2"
                    defaultMessage="To calculate dose by weight use the Medication Dosing Calculator."
                  />
                </span>
              )}
          </p>
          <span className="btn btn-primary btn-rounded Ripple-parent">
            <DoseCalculator showPatientWeightUnitOption="true" />
          </span>
          {this.props.symptomDetails &&
            this.props.symptomDetails.symptoms &&
            this.props.symptomDetails.symptoms.length > 0 && (
              <span>
                <hr />
                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section5.Title"
                    defaultMessage="Other symptoms"
                  />
                </h2>
                <div className="col-lg-9 col p-0">
                  <p>
                    <FormattedMessage
                      id="Results.Section5.P1"
                      defaultMessage="You reported that the patient is also experiencing the following symptoms. Learn more about the symptoms and see treatment options below."
                    />
                  </p>
                  <div className="open-modal-icons text-center flex-wrap d-flex">
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("rash") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(2)}
                        >
                          <SymptomRash />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf(
                        "sore throat"
                      ) !== -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(1)}
                        >
                          <SymptomSoreThroat />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("cough") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(3)}
                        >
                          <SymptomCough />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("diarrhea") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(4)}
                        >
                          <SymptomDiarrhea />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("fatigue") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(5)}
                        >
                          <SymptomFatigue />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("headache") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(6)}
                        >
                          <SymptomHeadache />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf(
                        "short breath"
                      ) !== -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(7)}
                        >
                          <SymptomShortBreath />
                        </span>
                      )}
                    {this.props.symptomDetails &&
                      this.props.symptomDetails.symptoms &&
                      this.props.symptomDetails.symptoms.indexOf("vomiting") !==
                      -1 && (
                        <span
                          className="flex-100-xs"
                          onClick={() => this.toggle(8)}
                        >
                          <SymptomVomiting />
                        </span>
                      )}
                  </div>
                </div>
              </span>
            )}
          {this.props.symptomDetails &&
            this.props.symptomDetails.immunizationFlag &&
            this.props.symptomDetails.immunizationFlag !== "yes" && (
              <span>
                <hr />
                <h2 className="underline light mb-4">
                  <FormattedMessage
                    id="Results.Section6.Title"
                    defaultMessage="Immunization status"
                  />
                </h2>
              </span>
            )}
          {this.props.symptomDetails &&
            this.props.symptomDetails.immunizationFlag &&
            this.props.symptomDetails.immunizationFlag !== "yes" && (
              <div className="col-9 p-0">
                <p>
                  <FormattedMessage
                    id="Results.Section6.P1"
                    defaultMessage="You reported that the patient is not up to date on routine immunizations. To locate providers near you offering routine vaccines, visit "
                  />
                  <a href="https://vaccinefinder.org" className="link">
                    HealthMap Vaccine Finder
                  </a>.
                </p>
              </div>
            )}
        </div>
      </Container>
    );
  }
}

// export default Results;

function mapStateToProps(state) {
  return {
    feverRecommendationNavigationIndex:
      state.feverRecommendationsState.feverRecommendationNavigationIndex,
    symptomDetails: state.feverRecommendationsState.symptomDetails,
    feverRecommendations: state.feverRecommendationsState.feverRecommendations,
    referral: state.feverRecommendationsState.referral
  };
}

function mapDispatchToProps(dispatch) {
  return {
    guidanceActions: bindActionCreators(GuidanceActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Results);
