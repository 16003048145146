import React, { Component } from "react";
import { FormattedMessage } from "react-intl";

class ModalSoreThroat extends Component {
  render() {
    return (
      <div className="symptoms-details">
         <p>
          <FormattedMessage
            id="ModalSoreThroat.P1"
            defaultMessage="A sore throat often accompanies an infection caused by a virus or bacteria. Bacterial infections require antibiotics for treatment, while viral infections usually go away on their own."
          />
        </p>
         <p>
          <FormattedMessage
            id="ModalSoreThroat.P2"
            defaultMessage="It is hard to tell the difference between strep throat and a sore throat caused by a viral infection. Strep throat can make the roof of your child's mouth turn red and the tonsils appear white. Strep throat is not usually accompanied by cough, runny nose, or itchy and watery eyes. If you think your child may have strep throat, call your pediatrician or primary care provider. He or she can do a test to check."
          />
        </p>
        <h4 className="secondary mb-3 mt-4">
          <FormattedMessage
            id="Modals.TreatmentOptions.Header"
            defaultMessage="Treatment options"
          />
        </h4>
 <p>
          <FormattedMessage
            id="ModalSoreThroat.P3"
            defaultMessage="To ease a sore throat, you can try the following:"
          />
        </p>
        <ul>
          <li>
        <FormattedMessage
            id="ModalSoreThroat.List1Item1"
            defaultMessage="Give your child acetaminophen (Tylenol) or ibuprofen (Advil, Motrin). Do not use aspirin in children under 18 years"
          />
		</li>
          <li>
        <FormattedMessage
            id="ModalSoreThroat.List1Item2"
            defaultMessage="Give plenty of fluids"
          />
		</li>
          <li>
        <FormattedMessage
            id="ModalSoreThroat.List1Item3"
            defaultMessage="Cold foods and drinks, such as popsicles or ice cream, can help soothe a sore throat"
          />
		</li>
        </ul>
      </div>
    );
  }
}

export default ModalSoreThroat;
